import React, { useState } from 'react';
import {
  convertFiltersV1ToV2,
  InsightsFilterDate,
  InsightsFilterV2,
} from '@magicbrief/common';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
  TabPanel,
  Tabs,
} from 'react-aria-components';
import InfiniteScroll from 'react-infinite-scroller';
import { useLoaderData, useParams, useRouteLoaderData } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmptyState from 'src/assets/svgicons/custom/EmptyState.svg';
import Settings01 from 'src/assets/svgicons/duocolor/settings-01.svg';
import Edit05 from 'src/assets/svgicons/duotone/edit-05.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { AriaButton } from 'src/components/Button/Button';
import {
  HeadlineTab,
  HeadlineTabAside,
  HeadlineTabList,
  HeadlineTabSubtitle,
  HeadlineTabTitle,
} from 'src/components/HeadlineTabs/HeadlineTabs';
import { Icon } from 'src/components/Icon';
import LibraryGrid from 'src/components/LibraryGrid';
import Spinner from 'src/components/Loaders/Spinner';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { GetAdAccountResponse } from 'src/types/insights';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useInsightsDetailContext } from '../../InsightsDetailOutlet/context';
import { InsightsCard } from '../../components/InsightsCard/InsightsCard';
import { InsightsGroupCard } from '../../components/InsightsCard/InsightsGroupCard';
import { InsightsFilterDisplayMenu } from '../../components/InsightsFilter/InsightsFilterDisplayMenu';
import InsightsFilterMetricList from '../../components/InsightsFilter/InsightsFilterMetricList';
import InsightsFilterMetricListTableView from '../../components/InsightsFilter/InsightsFilterMetricListTableView';
import { InsightsFilterMetricMenu } from '../../components/InsightsFilter/InsightsFilterMetricMenu';
import InsightsFilterSortList from '../../components/InsightsFilter/InsightsFilterSortList';
import InsightsFilterTimePeriodMenu from '../../components/InsightsFilter/InsightsFilterTimePeriodMenu';
import InsightsListBaseToolbar from '../../components/InsightsListToolbar/InsightsListBaseToolbar';
import InsightsRefreshDataButton from '../../components/InsightsListToolbar/InsightsRefreshDataButton';
import { InsightsAdPreview } from '../../components/InsightsMedia/components/InsightsAdPreview';
import { InsightsPage } from '../../components/InsightsPage/InsightsPage';
import { InsightsTable } from '../../components/InsightsTable/InsightsTable';
import { InsightsViewRadioGroup } from '../../components/InsightsViewRadioGroup';
import { InsightsPersistentStateProvider } from '../../context/InsightsPersistentStateProvider';
import { INSIGHTS_PAGE_TAKE_LIMIT } from '../../util/constants';
import { useInsightsDefaultDatePreset } from '../../util/useInsightsDefaultDatePreset';
import {
  useInsightsJob,
  useInsightsLastSyncedTime,
} from '../../util/useInsightsJob';
import {
  useInsightsFilter,
  useInsightsLayout,
  useInsightsPlatform,
  useInsightsSort,
} from '../../util/useInsightsPersistentState';
import {
  useInsightsAdAccount,
  useInsightsAdsQuery,
  useInsightsStatisticsQuery,
} from '../../util/useInsightsQueries';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';
import { insightsAccountRouteLoader } from '../InsightsAccount/route-loader';
import { InsightsAnalysisSprintSettings } from './components/InsightsAnalysisSprintSettings/InsightsAnalysisSprintSettings';
import { insightsAnalysisFilterToShortDescription } from './util';

type InsightsAnalysisTab = 'new' | 'losers' | 'winners' | 'scaling';

const InsightsAnalysis: React.FC = () => {
  const { accountUuid } = useParams();
  const account = useLoaderData() as GetAdAccountResponse;
  return (
    <InsightsPersistentStateProvider
      type="standard"
      view="analysis"
      platform={account.platform}
      accountUuid={accountUuid ?? ''}
    >
      <InsightsAnalysisContent />
    </InsightsPersistentStateProvider>
  );
};

const InsightsAnalysisContent: React.FC = () => {
  const { LL } = useI18nContext();
  const { getParsedValues } = useInsightsSearchParams();
  const platform = useInsightsPlatform();
  const { forTimePeriod, attributionWindow } = getParsedValues();
  const { recordEvent } = useNewAnalyticsEvent();
  const layout = useInsightsLayout();
  const trpcUtils = trpc.useUtils();

  const [tab, setTab] = useState<InsightsAnalysisTab>('new');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const { accountUuid } = useParams();

  const { accountCustomEvents, accountCustomConversions } =
    useInsightsAdAccount({
      accountUuid,
    });

  const adAccount = useRouteLoaderData('insights-account') as Awaited<
    ReturnType<typeof insightsAccountRouteLoader>
  >;

  const getSprintCounts = trpc.insights.getSprintCounts.useQuery(
    {
      uuid: accountUuid ?? '',
      forTimePeriod,
      attributionWindow,
    },
    { enabled: !!accountUuid, keepPreviousData: true }
  );

  const getSprintSettings = trpc.insights.getSprintSettings.useQuery(
    { uuid: accountUuid ?? '' },
    { enabled: !!accountUuid, keepPreviousData: true }
  );

  const updateSprintSettings = trpc.insights.updateSprintSettings.useMutation();

  const activeSprint = getSprintSettings.data?.sprints.find(
    (x) => x.id === tab
  );

  useInsightsDefaultDatePreset();

  if (getSprintCounts.isLoading || getSprintSettings.isLoading) {
    return (
      <InsightsPage
        className={
          layout === 'table'
            ? 'h-[calc(100vh-72px)] overflow-hidden'
            : undefined
        }
        toolbar={<InsightsAnalysisToolbar />}
      >
        <InsightsAnalysisTableSkeleton />
      </InsightsPage>
    );
  }

  return (
    <>
      <InsightsPage
        className={
          layout === 'table'
            ? 'h-[calc(100vh-72px)] overflow-hidden'
            : undefined
        }
        toolbar={
          <InsightsAnalysisToolbar
            openSettings={() => setIsSettingsOpen(true)}
          />
        }
      >
        <Tabs
          className={classNames(
            'flex min-w-0 flex-col',
            layout === 'grid'
              ? 'flex min-h-0 flex-col gap-4'
              : 'h-full overflow-hidden'
          )}
          orientation="horizontal"
          selectedKey={tab}
          onSelectionChange={(key) => {
            setTab(key as typeof tab);
            void recordEvent({
              action: 'Sprint Changed',
              target: 'Insights Copilot',
              metadata: {
                sprint: key,
              },
            });
          }}
        >
          <HeadlineTabList className="min-w-0 lg:h-36">
            {getSprintSettings.data?.sprints.map((sprint, i) => (
              <HeadlineTab
                id={sprint.id}
                key={sprint.id}
                className={classNames(
                  'group/tab relative z-0 flex min-w-0 flex-col justify-between gap-2 overflow-hidden outline-none transition-all data-[focused]:z-10 data-[focus-visible]:outline-purple-700 lg:h-36',
                  layout === 'grid'
                    ? i === 0
                      ? 'rounded-bl-xl'
                      : i === getSprintSettings.data?.sprints.length - 1
                      ? 'rounded-br-xl'
                      : ''
                    : 'selected:border-b-transparent'
                )}
              >
                {sprint.id !== 'new' && (
                  <Button
                    className="absolute bottom-3 right-3 opacity-0 group-hover/tab:opacity-100 focus:outline-none lg:bottom-[18px] lg:right-6"
                    onPress={() => setIsSettingsOpen(true)}
                  >
                    <Icon className="text- size-[18px]">
                      <Edit05 />
                    </Icon>
                  </Button>
                )}

                <span className="z-10 flex flex-row justify-between">
                  <HeadlineTabTitle className="relative">
                    <AnimatePresence mode="popLayout">
                      {[
                        ...(getSprintCounts.data?.[sprint.name]?.toString() ??
                          ''),
                      ].map((char, charIndex) => (
                        <motion.span
                          className="inline-block text-3xl md:text-[40px]"
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${char}-${charIndex}`}
                          transition={{
                            type: 'spring',
                            duration: 0.5,
                            delay: charIndex * 0.1,
                          }}
                          initial={{ opacity: 0, y: 15 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -15 }}
                        >
                          {char}
                        </motion.span>
                      ))}
                    </AnimatePresence>
                  </HeadlineTabTitle>
                </span>
                <span className="flex min-w-0 flex-col">
                  <HeadlineTabSubtitle>
                    <span className="absolute -right-1 -top-1 rotate-12 text-5xl opacity-50 md:static md:rotate-0 md:text-base md:opacity-100">
                      {sprint.emoji}{' '}
                    </span>
                    <span>{sprint.name}</span>
                  </HeadlineTabSubtitle>
                  <HeadlineTabAside className="hidden min-w-0 truncate lg:block">
                    {insightsAnalysisFilterToShortDescription(
                      platform,
                      sprint.id,
                      convertFiltersV1ToV2(sprint.filter),
                      adAccount.currency,
                      forTimePeriod,
                      LL
                    )}
                  </HeadlineTabAside>
                </span>
              </HeadlineTab>
            ))}
          </HeadlineTabList>

          {getSprintSettings.data?.sprints.map((x) => (
            <TabPanel
              id={x.id}
              key={x.id}
              className={
                layout === 'grid'
                  ? 'relative flex w-full flex-auto flex-col gap-4 overflow-hidden'
                  : 'relative flex-auto overflow-auto rounded-b-xl border-x border-b border-solid border-purple-200 bg-white'
              }
            >
              {layout === 'grid' ? (
                <div className="flex flex-col divide-y rounded-[10px] border border-solid border-purple-200 bg-purple-50 shadow">
                  <InsightsFilterMetricList
                    customEvents={accountCustomEvents ?? []}
                    customConversions={accountCustomConversions ?? []}
                  />
                  <InsightsFilterSortList
                    customEvents={accountCustomEvents ?? []}
                    customConversions={accountCustomConversions ?? []}
                  />
                </div>
              ) : (
                <div className="sticky left-0 flex flex-col bg-white">
                  <InsightsFilterMetricListTableView
                    className="sticky left-0"
                    customEvents={accountCustomEvents ?? []}
                    customConversions={accountCustomConversions ?? []}
                  />
                </div>
              )}
              {!!activeSprint && (
                <InsightsAnalysisDataEntries
                  activeSprint={{
                    ...activeSprint,
                    filter: convertFiltersV1ToV2(activeSprint.filter),
                  }}
                />
              )}
            </TabPanel>
          ))}
        </Tabs>
      </InsightsPage>

      <ModalOverlay
        isOpen={isSettingsOpen}
        onOpenChange={setIsSettingsOpen}
        isDismissable={!updateSprintSettings.isLoading}
        className="fixed inset-0 z-500 flex min-h-full items-center justify-center overflow-y-auto bg-gray-900/60 p-4 text-center"
      >
        <Modal className="flex max-h-screen w-full max-w-3xl overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl">
          <Dialog className="relative flex w-full outline-none">
            {({ close }) => (
              <div className="flex min-w-0 flex-auto flex-col gap-3">
                <div className="flex flex-row items-center justify-between gap-4 text-purple-800">
                  <Heading className="truncate text-xl font-bold">
                    Copilot Settings
                  </Heading>
                  <Button
                    onPress={close}
                    isDisabled={updateSprintSettings.isLoading}
                  >
                    <Icon>
                      <XClose />
                    </Icon>
                  </Button>
                </div>

                <div className="overflow-auto">
                  <InsightsAnalysisSprintSettings
                    platform={platform}
                    onSubmit={(values) => {
                      if (accountUuid) {
                        updateSprintSettings.mutate(
                          {
                            sprintSettings: values,
                            insightsAdAccountFacebookUuid: accountUuid,
                          },
                          {
                            onSuccess: (data) => {
                              void trpcUtils.insights.getSprintCounts.invalidate();
                              trpcUtils.insights.getSprintSettings.setData(
                                { uuid: accountUuid },
                                data
                              );
                              void recordEvent({
                                action: 'Settings Changed',
                                target: 'Insights Copilot',
                                metadata: {
                                  ...values,
                                  insightsAdAccountFacebookUuid: accountUuid,
                                },
                              });
                              close();
                            },
                            onError: (e, opts) => {
                              toast.error(
                                e instanceof Error ? e.message : 'Unknown error'
                              );
                              Sentry.captureException(e, {
                                contexts: { opts },
                              });
                            },
                          }
                        );
                      }
                    }}
                    sprints={getSprintSettings.data?.sprints ?? []}
                    currency={adAccount.currency ?? 'Unknown Currency'}
                    customEvents={accountCustomEvents ?? []}
                    customConversions={accountCustomConversions ?? []}
                  >
                    <div className="grid grid-cols-2 gap-3">
                      <AriaButton
                        variant="secondary"
                        onPress={close}
                        isDisabled={updateSprintSettings.isLoading}
                      >
                        Cancel
                      </AriaButton>
                      <AriaButton
                        htmlType="submit"
                        loading={updateSprintSettings.isLoading}
                      >
                        Save
                      </AriaButton>
                    </div>
                  </InsightsAnalysisSprintSettings>
                </div>
              </div>
            )}
          </Dialog>
        </Modal>
      </ModalOverlay>
    </>
  );
};

const InsightsAnalysisToolbar = ({
  openSettings,
}: {
  openSettings?: () => void;
}) => {
  const { accountUuid } = useParams();
  const layout = useInsightsLayout();
  const { adAccount, accountCustomConversions, accountCustomEvents } =
    useInsightsAdAccount({
      accountUuid,
    });
  const getSprintSettings = trpc.insights.getSprintSettings.useQuery(
    { uuid: accountUuid ?? '' },
    { enabled: !!accountUuid }
  );
  const { recordEvent } = useNewAnalyticsEvent();

  const subtitle = useInsightsLastSyncedTime();

  return (
    <InsightsListBaseToolbar
      icon={null}
      title={adAccount.data?.name}
      subtitle={subtitle}
      className={layout === 'grid' ? 'sticky top-[72px]' : undefined}
      actions={
        <div className="flex flex-row items-center gap-3 overflow-auto pb-3">
          <InsightsRefreshDataButton />
          <AriaButton
            isDisabled={!getSprintSettings.data}
            variant="white"
            icon={
              <Icon>
                <Settings01 />
              </Icon>
            }
            onPress={() => {
              void recordEvent({
                action: 'Settings Opened',
                target: 'Insights Copilot',
              });
              if (openSettings) {
                openSettings();
              }
            }}
          >
            Copilot Settings
          </AriaButton>
          <InsightsFilterTimePeriodMenu data-intercom-target="insight_date" />
          <InsightsFilterMetricMenu
            customEvents={accountCustomEvents ?? null}
            customConversions={accountCustomConversions ?? null}
          />
          <InsightsFilterDisplayMenu
            customEvents={accountCustomEvents ?? null}
            customConversions={accountCustomConversions ?? null}
          />
          <InsightsViewRadioGroup />
        </div>
      }
    />
  );
};

type Sprint = {
  id: 'winners' | 'new' | 'scaling' | 'losers';
  name: string;
  filter: Array<InsightsFilterV2>;
  emoji: string;
};

const InsightsAnalysisDataEntries: React.FC<{ activeSprint: Sprint }> = ({
  activeSprint,
}) => {
  const showInsightsDetail = useInsightsDetailContext();
  const { getParsedValues } = useInsightsSearchParams();
  const { forTimePeriod } = getParsedValues();
  const { accountUuid } = useParams();
  const metric = useInsightsFilter();
  const sort = useInsightsSort();
  const layout = useInsightsLayout();

  const { adAccount, accountCustomConversions, accountCustomEvents } =
    useInsightsAdAccount({
      accountUuid,
    });

  const job = useInsightsJob();

  const adStartAtFilter: InsightsFilterDate =
    forTimePeriod.datePreset === 'custom'
      ? {
          field: 'adCreatedAt',
          operation: 'between',
          start: new Date(forTimePeriod.since),
          end: new Date(forTimePeriod.until),
        }
      : {
          field: 'adCreatedAt',
          operation: 'withinDatePreset',
          value: forTimePeriod.datePreset,
        };

  const filters =
    activeSprint.id === 'new'
      ? [adStartAtFilter, ...metric]
      : activeSprint.filter
      ? [...activeSprint.filter, ...metric, adStartAtFilter]
      : [adStartAtFilter];

  const { statistics } = useInsightsStatisticsQuery({
    type: 'account',
    uuid: activeSprint?.filter ? accountUuid : undefined,
    jobStatus: job?.status,
    group: null,
    forTimePeriod,
    metric: filters,
    attributionWindow: null,
  });

  const { ads, getAdAtFlatIndex, onLoadMore } = useInsightsAdsQuery({
    type: 'account',
    uuid: activeSprint?.filter ? accountUuid : undefined,
    group: null,
    sort,
    jobStatus: job?.status,
    forTimePeriod,
    metric: filters,
    attributionWindow: null,
  });

  const hasNoAds = ads.data?.pages.flat().length === 0;

  if (!adAccount.data) {
    return null;
  }

  return (
    <>
      {layout === 'table' ? (
        <InfiniteScroll
          pageStart={0}
          className="flex w-full flex-auto"
          initialLoad={false}
          loadMore={onLoadMore}
          hasMore={ads.hasNextPage}
          useWindow={false}
          threshold={1200}
        >
          {ads.isLoading ? (
            <div className="mt-32 flex h-full w-full items-center justify-center text-primary">
              <Spinner />
            </div>
          ) : hasNoAds ? (
            <div className="mt-32 flex h-full w-full items-center justify-center">
              <div className="flex w-[300px] flex-col items-center justify-center">
                <EmptyState />
                <Heading className="mt-6 text-center text-lg font-medium text-[#917DD2]">
                  No ads match your current Copilot settings
                </Heading>
              </div>
            </div>
          ) : (
            <InsightsTable
              customConversions={accountCustomConversions ?? []}
              customEvents={accountCustomEvents ?? []}
              headerClassName="bg-white"
              statistics={statistics.data ?? null}
              ads={ads.data?.pages.flat() ?? []}
              currency={adAccount.data.currency}
              type="analysis"
            />
          )}
        </InfiniteScroll>
      ) : (
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={onLoadMore}
          hasMore={ads.hasNextPage}
          threshold={1200}
          className="flex w-full flex-auto"
        >
          {hasNoAds && !ads.isLoading ? (
            <div className="mt-1.5 flex h-full w-full items-center justify-center">
              <div className="flex w-[300px] flex-col items-center justify-center">
                <EmptyState />
                <Heading className="mt-6 text-center text-lg font-medium text-[#917DD2]">
                  No ads match your current Copilot settings
                </Heading>
              </div>
            </div>
          ) : (
            <LibraryGrid
              isLoading={ads.isLoading || adAccount.isLoading}
              isFetching={ads.isFetching}
            >
              {ads.data?.pages.map((page, pageIndex) =>
                page.map((ad, adIndex) =>
                  ad.type === 'ad' ? (
                    <InsightsCard
                      ad={ad}
                      customConversions={accountCustomConversions ?? []}
                      customEvents={accountCustomEvents ?? []}
                      key={ad.uuid}
                      currency={ad.currency}
                      statistics={statistics.data ?? null}
                      data-intercom-target={
                        adIndex === 0 ? 'insight_card' : undefined
                      }
                      showAdDetailsModal={() => {
                        showInsightsDetail({
                          uuid: ad.uuid,
                          data: {
                            ad,
                            pagination: {
                              flatIndex:
                                pageIndex > 1
                                  ? pageIndex * INSIGHTS_PAGE_TAKE_LIMIT +
                                    adIndex
                                  : adIndex,
                              getAdAtFlatIndex,
                            },
                          },
                        });
                      }}
                    />
                  ) : (
                    <InsightsGroupCard
                      key={ad.group}
                      group={ad}
                      currency={adAccount.data.currency}
                      statistics={statistics.data ?? null}
                      customConversions={accountCustomConversions ?? []}
                      customEvents={accountCustomEvents ?? []}
                    />
                  )
                )
              )}
            </LibraryGrid>
          )}
        </InfiniteScroll>
      )}
      <InsightsAdPreview />
    </>
  );
};

export default InsightsAnalysis;

export function InsightsAnalysisTableSkeleton() {
  return (
    <div className="flex h-full min-w-0 animate-pulse flex-col overflow-hidden rounded-lg border border-solid border-purple-200 bg-gray-50">
      <ul className="grid h-36 grid-cols-4">
        <li className="animate-pulse border-r border-solid border-b-purple-200 border-r-purple-200 bg-gray-50" />
        <li className="border-b border-solid border-b-purple-200 bg-purple-50" />
        <li className="border-b border-solid border-b-purple-200 bg-purple-50" />
        <li className="border-b border-solid border-b-purple-200 bg-purple-50" />
      </ul>
      <div className="animate-pulse bg-gray-50" />
    </div>
  );
}
