import React, { useMemo } from 'react';
import { Provider, atom } from 'jotai';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { inferProcedureOutput } from '@trpc/server';
import {
  InsightsPersistentStateContext,
  InsightsView,
  createInsightsAtom,
} from '../util/useInsightsPersistentState';

type InsightsPersistentStateContextStandardParam = {
  platform: 'facebook' | 'tiktok';
  type: 'standard';
  accountUuid: string;
  view: InsightsView;
};

type InsightsPersistentStateContextReportParam = {
  platform: 'facebook' | 'tiktok';
  type: 'report';
  reportUuid: string;
  initialState: inferProcedureOutput<
    AppRouter['insightsReports']['getInsightsReport']
  >['config'];
};

type InsightsPersistentStateContextComparisonReportParam = {
  platform: 'facebook' | 'tiktok';
  type: 'comparison-report';
  reportUuid: string;
  initialState: inferProcedureOutput<
    AppRouter['insightsComparisonReports']['getOne']
  >;
};

type InsightsPersistentStateContextParams =
  | InsightsPersistentStateContextStandardParam
  | InsightsPersistentStateContextReportParam
  | InsightsPersistentStateContextComparisonReportParam;

export const InsightsPersistentStateProvider: React.FC<
  React.PropsWithChildren<InsightsPersistentStateContextParams>
> = ({ children, ...rest }) => {
  const type = rest.type;
  const platform = rest.platform;
  const view = rest.type === 'standard' ? rest.view : 'overview';
  const accountUuid = rest.type === 'standard' ? rest.accountUuid : '';
  const reportUuid = rest.type !== 'standard' ? rest.reportUuid : '';
  const initialState = rest.type !== 'standard' ? rest.initialState : null;

  const atoms = useMemo(() => {
    const all = createInsightsAtom(
      platform,
      type === 'standard'
        ? { type, accountUuid, view }
        : type === 'report'
        ? {
            type,
            reportUuid,
            initialState:
              initialState as InsightsPersistentStateContextReportParam['initialState'],
          }
        : {
            type,
            reportUuid,
            initialState:
              initialState as InsightsPersistentStateContextComparisonReportParam['initialState'],
          }
    );
    const filter = atom((get) => get(all).filter);
    const display = atom((get) => get(all).display);
    const sort = atom((get) => get(all).sort);
    const group = atom((get) => get(all).group);
    const columnSizing = atom((get) => get(all).columnSizing);
    const graphMetrics = atom((get) => get(all).graphMetrics);
    const selected = atom((get) => get(all).selected);
    const layout = atom((get) => get(all).layout);
    const tableColorScheme = atom((get) => get(all).tableColorScheme);
    const comparisons = atom((get) => get(all).comparisons);

    return {
      all,
      platform,
      filter,
      display,
      sort,
      group,
      columnSizing,
      graphMetrics,
      selected,
      layout,
      tableColorScheme,
      comparisons,
    };
  }, [type, accountUuid, reportUuid, view, initialState, platform]);

  return (
    <Provider>
      <InsightsPersistentStateContext.Provider value={atoms}>
        {children}
      </InsightsPersistentStateContext.Provider>
    </Provider>
  );
};
