import { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  getAllMetricsAndAttributesForPlatform,
  sortAlphabetically,
} from '@magicbrief/common';
import Fuse from 'fuse.js';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import {
  getMetricDefinitions,
  getMetricLabelAsText,
  getMetricSynonyms,
} from './useParseMetric';

type SearchableItem = {
  metric: string;
  label: string;
  synonyms: string;
  definition: string;
};

type UseInsightsMetricsListParam = {
  /**
   * List of allowable metrics. Defaults to all metrics if not provided.
   * This should be memoised or a static reference to avoid performance issues.
   */
  metrics?: string[];
  platform: 'facebook' | 'tiktok';
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
  /** Adds custom events and conversions to the returned list of metrics. Should usually be true. Set to false when the `metrics` list is definitive. */
  shouldInjectCustomEventsAndConversions: boolean;
  /** Prevents sorting of the metric list when true. False by default. */
  preserveOrder?: boolean;
};

export function useInsightsMetricsList({
  metrics,
  platform,
  customEvents,
  customConversions,
  preserveOrder = false,
  shouldInjectCustomEventsAndConversions,
}: UseInsightsMetricsListParam) {
  const allMetrics = metrics ?? getAllMetricsAndAttributesForPlatform(platform);

  const searchableItems = useMemo(() => {
    const list = [
      ...allMetrics,
      ...(shouldInjectCustomEventsAndConversions
        ? customEvents ?? []
        : []
      ).flatMap((x) => [
        `action:cost_per:${x}`,
        `action:total:${x}`,
        `action:value:${x}`,
      ]),
      ...(shouldInjectCustomEventsAndConversions
        ? customConversions ?? []
        : []
      ).flatMap((x) => {
        const fullName = `offsite_conversion.custom.${x.facebookId}`;
        return [
          `action:cost_per:${fullName}`,
          `action:total:${fullName}`,
          `action:value:${fullName}`,
        ];
      }),
    ].reduce<Array<SearchableItem>>((acc, option) => {
      const label = getMetricLabelAsText(
        platform,
        option,
        customEvents,
        customConversions
      );
      if (label == null) {
        return acc;
      }

      const definition =
        getMetricDefinitions(platform, option)?.toString() ?? '';
      const synonyms = getMetricSynonyms(platform, option)?.toString() ?? '';

      acc.push({ metric: option, label, synonyms, definition });
      return acc;
    }, []);
    if (preserveOrder) return list;
    return list.sort((a, b) => sortAlphabetically(a.label, b.label));
  }, [
    customEvents,
    customConversions,
    allMetrics,
    preserveOrder,
    platform,
    shouldInjectCustomEventsAndConversions,
  ]);

  return searchableItems;
}

export function useMetricFuse(items: SearchableItem[]) {
  const fuse = useMemo(
    () =>
      new Fuse(items, {
        keys: [
          { name: 'label', weight: 1 },
          { name: 'metric', weight: 2 },
          { name: 'synonyms', weight: 3 },
          { name: 'definition', weight: 4 },
        ],
      }),
    [items]
  );
  return fuse;
}

export function useMetricSearch({
  platform,
  builtinMetrics,
  customConversions,
  customEvents,
  shouldInjectCustomEventsAndConversions = true,
}: {
  platform: 'facebook' | 'tiktok';
  builtinMetrics: string[];
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
  /** Adds custom events and conversions to the returned list of metrics. Should usually be true. Set to false when the `metrics` list is definitive. */
  shouldInjectCustomEventsAndConversions?: boolean;
}) {
  const [query, setQuery] = useState('');

  const { debouncedRecordEvent } = useNewAnalyticsEvent();

  const searchableItems = useInsightsMetricsList({
    platform,
    metrics: builtinMetrics,
    customEvents,
    customConversions,
    shouldInjectCustomEventsAndConversions,
  });

  const fuse = useMetricFuse(searchableItems);

  const results = useMemo(
    () =>
      query.trim() === ''
        ? searchableItems.map((item) => item.metric)
        : fuse.search(query).map((result) => result.item.metric),
    [fuse, query, searchableItems]
  );

  const onChange = useDebouncedCallback((query: string) => {
    if (query.length > 2) {
      void debouncedRecordEvent({
        action: 'Search',
        target: 'Insights Metric',
        metadata: {
          searchTerm: query,
        },
      });
    }
    setQuery(query);
  }, 150);

  return {
    query,
    onChange,
    results,
  };
}
