import { AppRouter } from '@magicbrief/server/src/trpc/router';
import * as Sentry from '@sentry/react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { getQueryKey, TRPCClientErrorLike } from '@trpc/react-query';
import { UseTRPCMutationOptions } from '@trpc/react-query/shared';
import { inferProcedureInput, inferProcedureOutput } from '@trpc/server';
import { toast } from 'react-toastify';
import { useI18nContext } from 'src/i18n/i18n-react';
import { segment } from 'src/lib/segment';
import { trpc } from 'src/lib/trpc';
import { AdResponse, GetAdsOutput, ShallowAdResponse } from 'src/types/ad';

function findAdInInfiniteQuery(
  uuid: string,
  data?: InfiniteData<GetAdsOutput>
) {
  const pages = data?.pages;
  if (pages) {
    const pageIdx = pages?.findIndex((x) => x.ads.find((y) => y.uuid === uuid));
    if (pageIdx !== -1) {
      const itemIdx = pages[pageIdx].ads.findIndex((x) => x.uuid === uuid);
      if (itemIdx !== -1) {
        const item = pages[pageIdx].ads[itemIdx];
        return { item, pageIdx, itemIdx };
      }
    }
  }
  return null;
}

function findOrganisationAdInInfiniteQuery(
  organisationAdUuid: string,
  data?: InfiniteData<GetAdsOutput>
) {
  const pages = data?.pages;
  if (pages) {
    const pageIdx = pages?.findIndex((x) =>
      x.ads.find((y) => y.organisationAdUUID === organisationAdUuid)
    );
    if (pageIdx !== -1) {
      const itemIdx = pages[pageIdx].ads.findIndex(
        (x) => x.organisationAdUUID === organisationAdUuid
      );
      if (itemIdx !== -1) {
        const item = pages[pageIdx].ads[itemIdx];
        return { item, pageIdx, itemIdx };
      }
    }
  }
  return null;
}

export function setAdInInfiniteQueryToSaved(
  withPlatformAdUUID: string,
  organisationAdUUID: string,
  newCollectionID?: number | string,
  data?: InfiniteData<GetAdsOutput>
): InfiniteData<GetAdsOutput> | undefined {
  const result = findAdInInfiniteQuery(withPlatformAdUUID, data);
  if (data && result) {
    return {
      ...data,
      pages: [
        ...data.pages.slice(0, result.pageIdx),
        {
          ...data.pages[result.pageIdx],
          ads: [
            ...data.pages[result.pageIdx].ads.slice(0, result.itemIdx),
            {
              ...result.item,
              organisationAdUUID,
              inDirectories:
                typeof newCollectionID === 'string'
                  ? [newCollectionID, ...result.item.inDirectories]
                  : result.item.inDirectories,
              inCollections:
                typeof newCollectionID === 'number'
                  ? [newCollectionID, ...result.item.inCollections]
                  : [...result.item.inCollections],
            },
            ...data.pages[result.pageIdx].ads.slice(result.itemIdx + 1),
          ],
        },
        ...data.pages.slice(result.pageIdx + 1),
      ],
    };
  }
  return data;
}

export function updateAdInInfiniteQuery(
  uuid: string,
  update: (ad: ShallowAdResponse) => ShallowAdResponse,
  data?: InfiniteData<GetAdsOutput>
): InfiniteData<GetAdsOutput> | undefined {
  const result = findAdInInfiniteQuery(uuid, data);
  if (data && result) {
    return {
      ...data,
      pages: [
        ...data.pages.slice(0, result.pageIdx),
        {
          ...data.pages[result.pageIdx],
          ads: [
            ...data.pages[result.pageIdx].ads.slice(0, result.itemIdx),
            update(result.item),
            ...data.pages[result.pageIdx].ads.slice(result.itemIdx + 1),
          ],
        },
        ...data.pages.slice(result.pageIdx + 1),
      ],
    };
  }
  return data;
}

export function updateOrganisationAdInInfiniteQuery(
  organisationAdUuid: string,
  update: (ad: ShallowAdResponse) => ShallowAdResponse,
  data?: InfiniteData<GetAdsOutput>
): InfiniteData<GetAdsOutput> | undefined {
  const result = findOrganisationAdInInfiniteQuery(organisationAdUuid, data);
  if (data && result) {
    return {
      ...data,
      pages: [
        ...data.pages.slice(0, result.pageIdx),
        {
          ...data.pages[result.pageIdx],
          ads: [
            ...data.pages[result.pageIdx].ads.slice(0, result.itemIdx),
            update(result.item),
            ...data.pages[result.pageIdx].ads.slice(result.itemIdx + 1),
          ],
        },
        ...data.pages.slice(result.pageIdx + 1),
      ],
    };
  }
  return data;
}

export function useSaveAd<TContext = unknown>(
  queryID: string | null,
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['ads']['addExistingAdToOrganisation']>,
    TRPCClientErrorLike<AppRouter['ads']['addExistingAdToOrganisation']>,
    inferProcedureOutput<AppRouter['ads']['addExistingAdToOrganisation']>,
    TContext
  >
) {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();
  const addExistingAdToOrg = trpc.ads.addExistingAdToOrganisation.useMutation({
    ...options,
    onSuccess: (orgAd, opts, context) => {
      void segment?.track('adsaved_discover');

      void queryClient.invalidateQueries(
        getQueryKey(
          trpc.ads.getCollectionsContainingPlatformAdInOrganisation,
          {
            platformAdUUID: opts.platformAdUUID,
          },
          'query'
        )
      );
      void queryClient.invalidateQueries(
        getQueryKey(trpc.ads.getAds, { queryType: 'library' }, 'any')
      );
      void queryClient.invalidateQueries(
        getQueryKey(trpc.ads.getOrganisationBrands, undefined, 'infinite')
      );
      void queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
        getQueryKey(trpc.ads.getAds, { queryType: 'discover' }, 'infinite'),
        (data) =>
          setAdInInfiniteQueryToSaved(
            opts.platformAdUUID,
            orgAd.uuid,
            undefined,
            data
          )
      );
      void queryClient.setQueriesData<AdResponse>(
        getQueryKey(
          trpc.ads.getAdByUUID,
          { uuid: opts.platformAdUUID },
          'query'
        ),
        (old) => (old ? { ...old, organisationAdUUID: orgAd.uuid } : old)
      );
      void queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
        getQueryKey(trpc.ads.getAds, { queryType: 'shared' }, 'infinite'),
        (data) =>
          setAdInInfiniteQueryToSaved(
            opts.platformAdUUID,
            orgAd.uuid,
            undefined,
            data
          )
      );
      void queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
        getQueryKey(
          trpc.ads.getAds,
          { queryType: 'private-brand-ads' },
          'infinite'
        ),
        (data) =>
          setAdInInfiniteQueryToSaved(
            opts.platformAdUUID,
            orgAd.uuid,
            undefined,
            data
          )
      );

      void queryClient.setQueriesData<
        InfiniteData<
          inferProcedureOutput<
            AppRouter['directories']['getDirectoryNodeContents']
          >
        >
      >(
        getQueryKey(
          trpc.directories.getDirectoryNodeContents,
          undefined,
          'infinite'
        ),
        (old) => {
          if (!old) return;
          for (let p = 0; p < old.pages.length; p++) {
            for (let i = 0; i < old.pages[p].length; i++) {
              const item = old.pages[p][i];
              if (item.OrganisationAd?.ad.uuid === opts.platformAdUUID) {
                const newData = {
                  ...old,
                  pages: [
                    ...old.pages.slice(0, p),
                    [
                      ...old.pages[p].slice(0, i),
                      {
                        ...item,
                        OrganisationAd: {
                          ...item.OrganisationAd,
                          uuid: orgAd.uuid,
                          ad: {
                            ...item.OrganisationAd.ad,
                            organisationAdUUID: orgAd.uuid,
                          },
                        },
                      },
                      ...old.pages[p].slice(i + 1),
                    ],
                    ...old.pages.slice(p + 1),
                  ],
                };

                return newData;
              }
            }
          }
          return old;
        }
      );

      return options?.onSuccess?.(orgAd, opts, context);
    },
    onError: (error, opts, context) => {
      Sentry.captureException(error, { contexts: { opts } });
      toast.error(
        LL.ad.errors.saveAdError({
          error: error instanceof Error ? error.message : 'Unknown error',
        }),
        { className: 'toast-danger' }
      );
      return options?.onError?.(error, opts, context);
    },
  });

  return addExistingAdToOrg;
}

export function useSaveAdToCollection<TContext = unknown>(
  queryID: string | null,
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['ads']['addAdToCollection']>,
    TRPCClientErrorLike<AppRouter['ads']['addAdToCollection']>,
    inferProcedureOutput<AppRouter['ads']['addAdToCollection']>,
    TContext
  >
) {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();
  const mutation = trpc.ads.addAdToCollection.useMutation({
    ...options,
    onSuccess: (orgAd, opts, context) => {
      if (orgAd.createdOrganisationAd) {
        void queryClient.invalidateQueries(
          getQueryKey(trpc.ads.getOrganisationBrands, undefined, 'infinite')
        );
      }
      queryClient.setQueryData<
        inferProcedureOutput<AppRouter['ads']['getCollections']>
      >(getQueryKey(trpc.ads.getCollections, undefined, 'query'), (old) => {
        if (old) {
          const existing = old.findIndex((x) => x.id === opts.collectionID);
          return existing !== -1
            ? [
                ...old.slice(0, existing),
                {
                  ...old[existing],
                  _count: {
                    adCollectionAdOrganisation:
                      old[existing]._count.adCollectionAdOrganisation + 1,
                  },
                },
                ...old.slice(existing + 1),
              ]
            : old;
        }
        return old;
      });
      void queryClient.invalidateQueries(
        getQueryKey(
          trpc.ads.getCollectionsContainingPlatformAdInOrganisation,
          {
            platformAdUUID: opts.platformAdUUID,
          },
          'query'
        )
      );
      void queryClient.invalidateQueries(
        getQueryKey(
          trpc.ads.getCollectionBrands,
          { collectionID: opts.collectionID },
          'query'
        )
      );
      void queryClient.invalidateQueries(
        getQueryKey(trpc.ads.getAds, { queryType: 'library' }, 'any')
      );
      queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
        getQueryKey(trpc.ads.getAds, { queryType: 'discover' }, 'infinite'),
        (data) =>
          setAdInInfiniteQueryToSaved(
            opts.platformAdUUID,
            orgAd.organisationAdUUID,
            orgAd.collectionID,
            data
          )
      );
      queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
        getQueryKey(trpc.ads.getAds, { queryType: 'shared' }, 'infinite'),
        (data) =>
          setAdInInfiniteQueryToSaved(
            opts.platformAdUUID,
            orgAd.organisationAdUUID,
            orgAd.collectionID,
            data
          )
      );
      queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
        getQueryKey(trpc.ads.getAds, { queryType: 'for-you' }, 'infinite'),
        (data) =>
          setAdInInfiniteQueryToSaved(
            opts.platformAdUUID,
            orgAd.organisationAdUUID,
            orgAd.collectionID,
            data
          )
      );
      void queryClient.setQueriesData<AdResponse>(
        getQueryKey(
          trpc.ads.getAdByUUID,
          { uuid: orgAd.platformAdUUID },
          'query'
        ),
        (old) =>
          old ? { ...old, organisationAdUUID: orgAd.organisationAdUUID } : old
      );

      return options?.onSuccess?.(orgAd, opts, context);
    },
    onError: (error, opts, context) => {
      Sentry.captureException(error, { contexts: { opts } });
      toast.error(
        LL.ad.errors.saveAdError({
          error: error instanceof Error ? error.message : 'Unknown error',
        }),
        { className: 'toast-danger' }
      );
      return options?.onError?.(error, opts, context);
    },
  });

  return mutation;
}
