import { atom } from 'jotai';
import type { EventTarget } from '@magicbrief/server/src/services/analytics/analytics';

type FeaturesModalState = {
  isOpen: boolean;
  target?: EventTarget;
};

export const showFeaturesModalAtom = atom<FeaturesModalState>({
  isOpen: false,
  target: undefined,
});
