import React, { forwardRef } from 'react';
import { ArrayElement, getMetricFormat } from '@magicbrief/common';
import { Header, flexRender } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { Checkbox, composeRenderProps } from 'react-aria-components';
import { useMatch } from 'react-router-dom';
import ArrowUp from 'src/assets/svgicons/duocolor/arrow-up.svg';
import { Icon } from 'src/components/Icon';
import { cn } from 'src/lib/cn';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import { COLOR_RANK_SCALE } from 'src/utils/rankColors';
import { INSIGHTS_TABLE_SELECTION_MAX } from '../const';
import {
  useInsightsGraphMetrics,
  useInsightsPlatform,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import { Props } from './InsightsTable';

type TableHeaderProps = {
  header: Header<ArrayElement<Props['ads']>, unknown>;
  className?: string;
  isDataGrouped: boolean;
  style?: React.CSSProperties;
};
export const TableHeader = forwardRef<
  HTMLTableCellElement,
  React.PropsWithChildren<TableHeaderProps>
>(({ header, className, style, children }, ref) => {
  const isPinned = header.column.getIsPinned();
  const styles: React.CSSProperties = {
    position: 'sticky',
    zIndex: isPinned === 'left' ? 3 : 2,
    top: 0,
    left:
      isPinned === 'left' ? `${header.column.getStart('left')}px` : undefined,
    width: `calc(var(--header-${header?.id}-size) * 1px)`,
    ...style,
  };

  if (header.isPlaceholder) {
    return null;
  }

  return (
    <th
      ref={ref}
      colSpan={header.colSpan}
      style={styles}
      className={cn(
        'relative box-border h-12 overflow-hidden border-b border-solid border-b-gray-200 bg-white text-left align-middle text-xs font-semibold',
        className
      )}
    >
      <button
        style={{
          width: `calc(var(--header-${header?.id}-size) * 1px)`,
        }}
        onClick={header.column.getToggleSortingHandler()}
        className="flex h-11 items-center gap-2 px-4 py-1 outline-none ring-purple-600 focus:outline-none focus-visible:ring-2"
      >
        {children}
        {flexRender(header.column.columnDef.header, header.getContext())}

        {(header.column.getIsSorted() as string) && (
          <Icon
            className={clsx(
              'size-3',
              header.column.getIsSorted() === 'desc' ? 'rotate-180' : ''
            )}
          >
            <ArrowUp />
          </Icon>
        )}
      </button>
      <button
        className={clsx(
          /** Base */
          'absolute -right-1 top-0 z-[9999] flex h-full w-2.5 cursor-col-resize touch-none select-none items-center justify-center duration-300',
          /** Hover */
          'hover:bg-purple-300',
          /** Resizing  */
          header.column.getIsResizing() && 'bg-purple-800'
        )}
        onDoubleClick={() => header.column.resetSize()}
        onMouseDown={header.getResizeHandler()}
        onTouchStart={header.getResizeHandler()}
      >
        <span
          className={cn(
            'block h-2/3 w-px bg-purple-300',
            header.column.getIsResizing() && 'bg-purple-800'
          )}
        />
      </button>
    </th>
  );
});

TableHeader.displayName = 'TableHeader';

export const DraggableTableHeader: React.FC<TableHeaderProps> = ({
  header,
  className,
  isDataGrouped,
}) => {
  const dispatch = useInsightsStoreDispatch();
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });
  const isAnalysis = useMatch('/insights/:accountUuid/analysis');

  const isPinned = header.column.getIsPinned();
  const styles: React.CSSProperties = {
    position: 'sticky',
    zIndex: isDragging ? 4 : isPinned === 'left' ? 3 : 2,
    top: 0,
    left:
      isPinned === 'left' ? `${header.column.getStart('left')}px` : undefined,
    width: `calc(var(--header-${header?.id}-size) * 1px)`,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition: 'width transform 0.2s ease-in-out',
  };

  const graphMetrics = useInsightsGraphMetrics();
  const platform = useInsightsPlatform();
  const graphMetricIndex = graphMetrics?.findIndex(
    (metric) => metric === header.column.id
  );
  const hasGraphMetric = graphMetricIndex !== -1;
  const graphMetricOrder = hasGraphMetric ? graphMetricIndex + 1 : null;

  const format = getMetricFormat(platform, header.column.id);

  const rankedColor =
    hasGraphMetric &&
    graphMetricOrder &&
    graphMetricOrder < INSIGHTS_TABLE_SELECTION_MAX + 1 &&
    (format === 'currency' || format === 'numeric' || format === 'percentage')
      ? COLOR_RANK_SCALE[graphMetricOrder - 1]
      : undefined;

  const handleToggleSort = (val: boolean) => {
    dispatch({
      type: val ? 'addGraphMetric' : 'removeGraphMetric',
      value: header.column.id,
    });
  };

  return (
    <TableHeader
      ref={setNodeRef}
      header={header}
      isDataGrouped={isDataGrouped}
      style={styles}
      className={className}
    >
      <button
        {...attributes}
        {...listeners}
        className="cursor-grab text-purple-300 active:cursor-grabbing"
      >
        <Icon className="h-4 w-4">
          <DotsGrid />
        </Icon>
      </button>
      {!isAnalysis && (
        <Checkbox
          isSelected={!!graphMetricOrder}
          onChange={handleToggleSort}
          slot="selection"
          className={composeRenderProps(className, (className) =>
            clsx(
              'group/checkbox flex cursor-pointer items-center justify-center disabled:cursor-not-allowed disabled:opacity-50',
              className
            )
          )}
          isDisabled={
            !graphMetrics?.includes(header.id) &&
            graphMetrics?.length >= INSIGHTS_TABLE_SELECTION_MAX
          }
        >
          <div
            className={clsx(
              /** Base */
              'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
              /** Selected */
              'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100'
            )}
            style={{
              backgroundColor: hasGraphMetric ? rankedColor : 'transparent',
              borderColor: hasGraphMetric
                ? rankedColor
                : 'rgb(64 34 164 / 0.5)',
            }}
          >
            <span className="hidden group-selected/checkbox:block">
              {graphMetricOrder}
            </span>
          </div>
        </Checkbox>
      )}
    </TableHeader>
  );
};
