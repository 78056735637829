import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { appConfigs } from 'src/configs';
import { auth } from './firebase';

const instance = axios.create({
  baseURL: appConfigs.host,
});

instance.interceptors.request.use(async (config) => {
  /**
   * Ask Firebase app instance for token. This
   * should always return the current token or a new
   * token if the cached one is expiring.
   *
   * @see https://firebase.google.com/docs/reference/js/auth.user.md#usergetidtoken
   */
  const firebaseToken = await auth.currentUser?.getIdToken();
  if (!firebaseToken) {
    Sentry.captureException(
      new Error('No Firebase token received by Axios request interceptor'),
      {
        user: {
          id: auth.currentUser?.uid,
          email: auth.currentUser?.email || undefined,
        },
        contexts: {
          requestInfo: {
            requestURL: config.url,
          },
        },
      }
    );
  }
  const authToken = `Bearer ${firebaseToken}`;
  config.headers = {
    ...config.headers,
    Authorization: authToken,
  };
  return config;
});

const handleExpiredToken = instance.interceptors.response.use(
  (res) => res,
  async function (error: AxiosError) {
    if (error?.response?.status === 403) {
      if (auth.currentUser) {
        const newToken = await auth.currentUser.getIdToken(true);

        if (newToken) {
          error.config.headers = {
            ...error.config.headers,
            Authorization: `Bearer ${newToken}`,
          };
          return axios.request(error.config);
        } else {
          Sentry.captureException(
            new Error(
              'Axios one-time 403 retry handler failed as new token could not be fetched from Firebase'
            ),
            {
              user: {
                id: auth.currentUser.uid,
                email: auth.currentUser.email || undefined,
              },
              contexts: {
                requestInfo: {
                  requestURL: error.config.url,
                },
              },
            }
          );
        }
      } else {
        Sentry.captureException(
          new Error(
            'Axios one-time 403 retry handler failed as auth.currentUser was null'
          ),
          {
            contexts: {
              requestInfo: {
                requestURL: error.config.url,
              },
            },
          }
        );
      }
    }

    instance.interceptors.response.eject(handleExpiredToken);

    return Promise.reject(error);
  }
);

export default instance;
