import { useState, useEffect } from 'react';
import { TOUR_LINK } from '@magicbrief/common';
import BarChartSquare01 from 'src/assets/svgicons/duotone/bar-chart-square-01.svg';
import SearchRefraction from 'src/assets/svgicons/duotone/search-refraction.svg';
import Signal03 from 'src/assets/svgicons/duotone/signal-03.svg';
import MessageNotificationCircle from 'src/assets/svgicons/duotone/message-notification-circle.svg';
import Trophy02 from 'src/assets/svgicons/duotone/trophy-02.svg';
import Activity from 'src/assets/svgicons/duotone/activity.svg';
import Settings01 from 'src/assets/svgicons/duotone/settings-01.svg';
import Lightning01 from 'src/assets/svgicons/duotone/lightning-01.svg';
import { cn } from 'src/lib/cn';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';

import { useI18nContext } from 'src/i18n/i18n-react';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import { AriaButton, ButtonLink } from '../Button';
import { BaseModal } from '../AriaModal';
import { Icon } from '../Icon';
import type { EventTarget } from '@magicbrief/server/src/services/analytics/analytics';

const FeaturesModal: React.FC<{
  title?: string;
  isOpen: boolean;
  close: () => void;
  dismissable?: boolean;
  target?: EventTarget;
}> = ({ title, isOpen, close, dismissable = true, target }) => {
  const [selectedFeature, setSelectedFeature] = useState<
    (typeof FEATURES)[number]
  >(FEATURES[0]);
  const { recordEvent } = useNewAnalyticsEvent();
  const { LL } = useI18nContext();

  const userAndOrg = useUserAndOrganisation();
  const isTrialExpired =
    userAndOrg.data?.organisation.billingState === 'trialExpired';

  useEffect(() => {
    if (isOpen) {
      void recordEvent({
        action: 'Features Modal Shown',
        target: target ?? 'Product Offer',
        metadata: {
          isTrialExpired,
        },
      });
    }
  }, [recordEvent, isOpen, isTrialExpired, target]);

  return (
    <BaseModal
      modalClassName="bg-white rounded-xl"
      onClose={dismissable ? close : null}
      show={isOpen}
    >
      <div className="divide-y divide-solid divide-purple-300 text-primary *:px-6 *:py-4">
        <h2 className="text-xl font-semibold">
          {title ?? LL.upgrade.trialExpired()}
        </h2>
        <div className="flex gap-6">
          <div className="space-y-2.5">
            {FEATURES.map((feature) => (
              <AriaButton
                key={feature.key}
                variant="custom"
                className={cn(
                  'h-fit max-w-[348px] rounded-lg hover:bg-purple-100',
                  selectedFeature.key === feature.key && 'bg-purple-100'
                )}
                onPress={() => setSelectedFeature(feature)}
              >
                <div className="flex items-start gap-2.5">
                  <Icon className="-mt-2 size-11">{feature.icon}</Icon>
                  <div className="space-y-1.5 text-left">
                    <p className="text-base font-semibold">
                      {LL.upgrade.features[feature.key].title()}
                    </p>
                    <p className="text-sm font-medium opacity-50">
                      {LL.upgrade.features[feature.key].description()}
                    </p>
                  </div>
                </div>
              </AriaButton>
            ))}
          </div>
          <div className="h-[589px] w-[530px]">
            <img
              src={`/images/features/${selectedFeature.imageName}.webp`}
              alt={LL.upgrade.features[selectedFeature.key].title()}
              className="h-full w-full rounded-2xl object-cover transition-all"
            />
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <ButtonLink
            type="external"
            href="https://magicbrief.com/pricing"
            className="mr-auto h-fit hover:underline"
            variant="custom"
          >
            {LL.workspaceSettings.viewAllPlans()}
          </ButtonLink>
          {dismissable && (
            <AriaButton onPress={close} variant="text">
              {LL.cancel()}
            </AriaButton>
          )}
          {!dismissable && (
            <ButtonLink
              type="internal"
              to="/settings/workspace"
              variant="white"
              icon={<Settings01 />}
              className="capitalize"
            >
              {LL.workspaceSettings.workspaceSettings()}
            </ButtonLink>
          )}
          <AriaButton
            onPress={() => {
              try {
                const newWindow = window.open(TOUR_LINK, '_blank');
                if (newWindow) {
                  void recordEvent({
                    action: 'Book Tour Link Clicked',
                    target: 'Upgrade Modal',
                  });
                } else {
                  // Fallback to regular navigation
                  window.location.href = TOUR_LINK;
                }
              } catch (error) {
                console.error('Failed to open onboarding:', error);
              }
            }}
            icon={<Lightning01 />}
          >
            Book Onboarding
          </AriaButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default FeaturesModal;

const FEATURES = [
  {
    key: 'advancedCreativeAnalytics',
    icon: <BarChartSquare01 />,
    imageName: 'feat-creative-analytics',
  },
  {
    key: 'adResearchEngine',
    icon: <SearchRefraction />,
    imageName: 'feat-research-engine',
  },
  {
    key: 'trackAnyBrandRunningAds',
    icon: <Signal03 />,
    imageName: 'feat-track-brands',
  },
  {
    key: 'automatedSlackReporting',
    icon: <MessageNotificationCircle />,
    imageName: 'feat-slack-reporting',
  },
  {
    key: 'seeWinnersWithRecommendations',
    icon: <Trophy02 />,
    imageName: 'feat-recommendations',
  },
  {
    key: 'checkAdAccountHealthInSeconds',
    icon: <Activity />,
    imageName: 'feat-ad-account-health',
  },
] as const;
