import { AppRouter } from '@magicbrief/server/src/trpc/router';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  getQueryKey,
  inferReactQueryProcedureOptions,
} from '@trpc/react-query';
import { inferProcedureOutput } from '@trpc/server';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteLastSavedAssetCollectionInLocalStorage,
  setLastSavedAssetCollectionInLocalStorage,
} from 'src/contexts/getSetLastSavedAssetCollection';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';

export function useCreateAssetCollection(
  onSuccess?: inferReactQueryProcedureOptions<AppRouter>['storage']['createAssetCollection']['onSuccess']
) {
  const queryClient = useQueryClient();
  const addAssetToNewCollection =
    trpc.storage.createAssetCollection.useMutation({
      onError: (e, opts) => {
        Sentry.captureException(e, { contexts: { opts } });
      },
      onSuccess: (result, opts, context) => {
        const { collection } = result;
        if (opts.withAssetUuid) {
          void queryClient.invalidateQueries(
            getQueryKey(trpc.storage.getUserAssets, undefined, 'infinite')
          );
          void queryClient.invalidateQueries(
            getQueryKey(trpc.storage.getAssetCollection, undefined, 'query')
          );
          queryClient.setQueryData<
            inferProcedureOutput<
              AppRouter['storage']['getCollectionsContainingAssetInOrganisation']
            >
          >(
            getQueryKey(
              trpc.storage.getCollectionsContainingAssetInOrganisation,
              { assetUuid: opts.withAssetUuid },
              'query'
            ),
            (old) => (old ? [collection, ...old] : old)
          );
          setLastSavedAssetCollectionInLocalStorage(collection.uuid);
        }
        queryClient.setQueryData<
          inferProcedureOutput<AppRouter['storage']['getAssetCollections']>
        >(
          getQueryKey(trpc.storage.getAssetCollections, undefined, 'query'),
          (old) => (old ? [collection, ...old] : old)
        );

        onSuccess?.(result, opts, context);
      },
    });
  return addAssetToNewCollection;
}

export function useAddAssetToExistingCollection() {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();
  const addAssetToExistingCollection =
    trpc.storage.addAssetToExistingCollection.useMutation({
      onError: (e, opts) => {
        toast.error(
          LL.assets.addToCollectionError({
            error: e instanceof Error ? e.message : 'Unknown error',
          }),
          { className: 'toast-danger' }
        );
        Sentry.captureException(e, { contexts: { opts } });
      },
      onSuccess: (result) => {
        const { UserAsset, AssetCollection } = result;

        void queryClient.invalidateQueries(
          getQueryKey(trpc.storage.getUserAssets, undefined, 'infinite')
        );
        void queryClient.invalidateQueries(
          getQueryKey(trpc.storage.getAssetCollection, undefined, 'query')
        );

        void queryClient.setQueryData<
          inferProcedureOutput<AppRouter['storage']['getAssetCollections']>
        >(
          getQueryKey(trpc.storage.getAssetCollections, undefined, 'query'),
          (old) => {
            if (!old) return old;
            const existingInd = old.findIndex(
              (collection) => collection.uuid === AssetCollection.uuid
            );
            if (existingInd !== -1) {
              return [
                ...old.slice(0, existingInd),
                {
                  ...old[existingInd],
                  _count: {
                    UserAssetsInAssetCollections:
                      old[existingInd]._count.UserAssetsInAssetCollections + 1,
                  },
                },
                ...old.slice(existingInd + 1),
              ];
            }

            return old;
          }
        );

        void queryClient.setQueryData<
          inferProcedureOutput<
            AppRouter['storage']['getCollectionsContainingAssetInOrganisation']
          >
        >(
          getQueryKey(
            trpc.storage.getCollectionsContainingAssetInOrganisation,
            { assetUuid: UserAsset.uuid },
            'query'
          ),
          (old) => (old ? [AssetCollection, ...old] : old)
        );

        setLastSavedAssetCollectionInLocalStorage(result.AssetCollection.uuid);
      },
    });
  return addAssetToExistingCollection;
}

export function useRemoveAssetsFromAssetCollection() {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();
  const removeAssetsFromCollection =
    trpc.storage.removeAssetsFromCollection.useMutation({
      onError: (e, opts) => {
        toast.error(
          LL.assets.addToCollectionError({
            error: e instanceof Error ? e.message : 'Unknown error',
          }),
          { className: 'toast-danger' }
        );
        Sentry.captureException(e, { contexts: { opts } });
      },
      onSuccess: (_, opts) => {
        const { assetUuids, collectionUuid } = opts;
        void queryClient.invalidateQueries(
          getQueryKey(trpc.storage.getUserAssets, undefined, 'infinite')
        );
        void queryClient.invalidateQueries(
          getQueryKey(trpc.storage.getAssetCollection, undefined, 'query')
        );

        assetUuids.forEach((assetUuid) => {
          void queryClient.setQueryData<
            inferProcedureOutput<
              AppRouter['storage']['getCollectionsContainingAssetInOrganisation']
            >
          >(
            getQueryKey(
              trpc.storage.getCollectionsContainingAssetInOrganisation,
              { assetUuid },
              'query'
            ),
            (old) =>
              old
                ? old.filter((collection) => collection.uuid !== collectionUuid)
                : old
          );
        });
      },
    });
  return removeAssetsFromCollection;
}

export function useDeleteAssetCollection() {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();
  const navigator = useNavigate();

  const deleteAssetFromCollection =
    trpc.storage.deleteAssetCollection.useMutation({
      onError: (e, opts) => {
        toast.error(
          LL.assets.addToCollectionError({
            error: e instanceof Error ? e.message : 'Unknown error',
          }),
          { className: 'toast-danger' }
        );
        Sentry.captureException(e, { contexts: { opts } });
      },
      onSuccess: (_, opts) => {
        const { uuid } = opts;
        void queryClient.invalidateQueries(
          getQueryKey(trpc.storage.getUserAssets, undefined, 'query')
        );

        void queryClient.invalidateQueries(
          getQueryKey(
            trpc.storage.getCollectionsContainingAssetInOrganisation,
            undefined,
            'query'
          )
        );

        void queryClient.setQueryData<
          inferProcedureOutput<AppRouter['storage']['getAssetCollections']>
        >(
          getQueryKey(trpc.storage.getAssetCollections, undefined, 'query'),
          (old) =>
            old ? old.filter((collection) => collection.uuid !== uuid) : old
        );

        deleteLastSavedAssetCollectionInLocalStorage(uuid);

        navigator('/assets');
      },
    });
  return deleteAssetFromCollection;
}

export function useEditAssetCollection(onSuccess?: () => void) {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();

  const editAssetCollection = trpc.storage.editAssetCollection.useMutation({
    onError: (e, opts) => {
      toast.error(
        LL.assets.addToCollectionError({
          error: e instanceof Error ? e.message : 'Unknown error',
        }),
        { className: 'toast-danger' }
      );
      Sentry.captureException(e, { contexts: { opts } });
    },
    onSuccess: (result) => {
      const { uuid, name } = result;
      void queryClient.invalidateQueries(
        getQueryKey(trpc.storage.getUserAssets, undefined, 'query')
      );
      void queryClient.setQueryData<
        inferProcedureOutput<AppRouter['storage']['getAssetCollection']>
      >(
        getQueryKey(trpc.storage.getAssetCollection, { uuid }, 'query'),
        (old) => (old ? { ...old, ...result } : old)
      );

      void queryClient.setQueryData<
        inferProcedureOutput<AppRouter['storage']['getAssetCollections']>
      >(
        getQueryKey(trpc.storage.getAssetCollections, undefined, 'query'),
        (old) => {
          if (!old) return old;

          const existingInd = old.findIndex(
            (collection) => collection.uuid === uuid
          );

          if (existingInd !== -1) {
            return [
              ...old.slice(0, existingInd),
              {
                ...old[existingInd],
                name,
              },
              ...old.slice(existingInd + 1),
            ];
          }

          return old;
        }
      );

      onSuccess?.();
    },
  });
  return editAssetCollection;
}
