import { EventTarget } from '@magicbrief/server/src/services/analytics/analytics';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { captureException } from '@sentry/react';
import { inferProcedureInput } from '@trpc/server';
import { useDebouncedCallback } from 'use-debounce';
import { trpcProxyClient } from 'src/lib/trpc';
import { getAnonymousId } from './user';

type Props = {
  target: EventTarget;
  action: string;
  metadata?: Record<string | number | symbol, unknown>;
};

async function recordEvent({ target, action, metadata = {} }: Props) {
  try {
    const anonymousId = getAnonymousId();
    const data: inferProcedureInput<AppRouter['userEvents']['userState']> = {
      target,
      action,
      anonymousIdentifier: anonymousId,
      metadata: {
        ...metadata,
        current_url: window.location.pathname,
      },
    };

    await trpcProxyClient.userEvents.userState.mutate(data);
  } catch (e) {
    captureException(e, { contexts: { event: { target, action, metadata } } });
  }
}

export default function useNewAnalyticsEvent() {
  const debouncedRecordEvent = useDebouncedCallback(recordEvent, 1000);

  return { recordEvent, debouncedRecordEvent };
}
