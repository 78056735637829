import {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  ArrayElement,
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS,
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_REFERENCE,
  InsightsFacebookWizardMetric,
  InsightsFilter,
  InsightsFilterExpression,
} from '@magicbrief/common';
import {
  Button,
  Dialog,
  Heading,
  Modal,
  ResizableTableContainer,
  Text,
} from 'react-aria-components';
import InfiniteScroll from 'react-infinite-scroller';
import { useMatch, useParams } from 'react-router-dom';
import Checkerboard from 'src/assets/imgs/checker.jpeg';
import ChevronRight from 'src/assets/svgicons/line/chevron-right.svg';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import ArrowUp from 'src/assets/svgicons/solid/arrow-up.svg';
import PlayCircle from 'src/assets/svgicons/solid/play-circle.svg';
import { MagicBriefButton } from 'src/components/Button/MagicBriefButton';
import { DrawerOverlay, DrawerTrigger } from 'src/components/Drawer/Drawer';
import { drawerContentVariants } from 'src/components/Drawer/Drawer.styles';
import { Icon } from 'src/components/Icon';
import Image from 'src/components/Image';
import Spinner from 'src/components/Loaders/Spinner';
import { Tooltip } from 'src/components/Tooltip/Tooltip';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { trpc } from 'src/lib/trpc';
import { getZIndexByClassName } from 'src/utils/getZIndexByClassName';
import { lerpColor } from 'src/utils/interpolateColour';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import {
  hasInsightsAdPreviewAtom,
  insightsAdPreviewAtom,
} from '../../Insights.atoms';
import { INSIGHTS_PAGE_TAKE_LIMIT } from '../../util/constants';
import {
  useInsightsDisplay,
  useInsightsFilter,
  useInsightsGroup,
  useInsightsPlatform,
  useInsightsSort,
} from '../../util/useInsightsPersistentState';
import {
  getNextPageParam,
  useInsightsAdAccount,
} from '../../util/useInsightsQueries';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';
import {
  getMetricLabelAsText,
  getMetricValue,
  getValueForMetric,
} from '../../util/useParseMetric';
import {
  INSIGHTS_WIZARD_TACOMETER_GREEN_COLOURS,
  INSIGHTS_WIZARD_TACOMETER_RED_COLOURS,
  INSIGHTS_WIZARD_TACOMETER_YELLOW_COLOURS,
} from '../const';
import {
  WizardScoreInfoLink,
  WizardScoreTooltip,
} from '../InsightsCard/components/InsightsCardInsights/WizardScore';
import {
  formatCustomDateLabel,
  getSinceAndUntilDates,
} from '../InsightsFilter/InsightsFilterTimePeriodMenuV2.utils';
import { InsightsMetricLabelTooltip } from '../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import type {
  InsightsAd,
  InsightsAdAsset,
  InsightsAdCreativeType,
  InsightsAdGroup,
  InsightsAdMetrics,
} from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

export function InsightsAdBreakdownPanel({
  adGroup,
  className,
  expression,
  comparisonId,
}: {
  adGroup: InsightsAdGroup;
  className?: string;
  expression?: InsightsFilter[] | InsightsFilterExpression;
  comparisonId?: string;
}) {
  const hasInsightsAdPreview = useAtomValue(hasInsightsAdPreviewAtom);
  const setInsightsAdPreview = useSetAtom(insightsAdPreviewAtom);

  /**
   * This is to prevent the floating intercom chat button from appearing over the breakdown panel
   * and the insights ad preview mounted within it
   */
  const intercomZIndex = getZIndexByClassName('intercom-lightweight-app');
  const groupBreakdownZIndex = intercomZIndex ? intercomZIndex + 1 : '1000';

  return (
    <DrawerTrigger
      onOpenChange={(isOpen) => {
        // Clear the insights ad preview when we close the breakdown panel
        if (!isOpen && hasInsightsAdPreview) {
          setInsightsAdPreview(null);
        }
      }}
    >
      <Button
        className={cn(
          'truncate text-xs font-normal text-purple-700 opacity-0 transition-opacity hover:underline group-hover/ad:opacity-100 focus:outline-none',
          className
        )}
      >
        Ad Breakdown
      </Button>

      <DrawerOverlay
        isKeyboardDismissDisabled={hasInsightsAdPreview}
        style={{ zIndex: groupBreakdownZIndex }}
      >
        <Modal
          className={cn(
            drawerContentVariants({ side: 'right' }),
            'w-[870px] overflow-auto'
          )}
        >
          <Dialog
            className="relative flex flex-col gap-4 overflow-y-auto px-6 focus-within:outline-none"
            id="insights-ad-breakdown"
          >
            {({ close }) => (
              <>
                <AdBreakdownClose close={close} />
                <AdBreakdownOverview adGroup={adGroup} />
                <AdBreakdownInformation
                  adGroup={adGroup}
                  expression={expression}
                  comparisonId={comparisonId}
                />
              </>
            )}
          </Dialog>
        </Modal>
      </DrawerOverlay>
    </DrawerTrigger>
  );
}

function AdBreakdownClose({ close }: { close: () => void }) {
  return (
    <div className="flex items-center py-5">
      <MagicBriefButton
        variant="secondary"
        size="medium"
        className="size-10 p-2.5"
        onPress={close}
      >
        <Icon>
          <ChevronRight />
        </Icon>
      </MagicBriefButton>
    </div>
  );
}

interface AdBreakdownOverviewProps {
  adGroup: InsightsAdGroup;
}

function AdBreakdownOverview({ adGroup }: AdBreakdownOverviewProps) {
  const { LL } = useI18nContext();
  const { forTimePeriod, since, until } =
    useInsightsSearchParams().getParsedValues();
  const tableMetrics = useInsightsDisplay();

  const sinceAndUntilDates =
    forTimePeriod.datePreset !== 'custom'
      ? getSinceAndUntilDates(forTimePeriod.datePreset)
      : { since, until };
  const presetLabel =
    LL.insights.timePeriod[
      forTimePeriod.datePreset as keyof typeof LL.insights.timePeriod
    ]();
  const timePeriodLabel =
    sinceAndUntilDates.since && sinceAndUntilDates.until
      ? formatCustomDateLabel({
          since: sinceAndUntilDates.since,
          until: sinceAndUntilDates.until,
        })
      : '';
  const dateLabel = `${presetLabel} ${timePeriodLabel}`;

  return (
    <div className="py-4">
      <div className="flex flex-col gap-2">
        <Heading className="text-xl font-semibold text-primary">
          {adGroup.group ?? 'None'}
        </Heading>
        <Text className="text-md font-medium text-[#917DD2]">{dateLabel}</Text>
      </div>
      <div className="mt-16 flex items-center justify-between gap-4">
        {tableMetrics.slice(0, 2).map((metric) => (
          <AdBreakdownCard
            key={metric}
            groupMetrics={adGroup.metrics}
            metric={metric}
          />
        ))}
        <div className="flex w-full flex-col gap-6 rounded-xl border border-solid border-purple-200 p-4 shadow-sm">
          <Heading className="text-md font-semibold text-[#917DD2]">
            Number of Ads
          </Heading>
          <Text className="text-3xl font-semibold text-primary">
            {adGroup.count.toString()}
          </Text>
        </div>
      </div>
    </div>
  );
}

interface AdBreakdownCardProps {
  groupMetrics: InsightsAdMetrics;
  metric: string;
}

function AdBreakdownCard({ groupMetrics, metric }: AdBreakdownCardProps) {
  const { accountUuid } = useParams();
  const { accountCustomEvents, accountCustomConversions, currency } =
    useInsightsAdAccount({
      accountUuid,
    });

  const platform = useInsightsPlatform();

  const label = getMetricLabelAsText(
    platform,
    metric,
    accountCustomEvents ?? [],
    accountCustomConversions ?? []
  );
  const parsedValue = getMetricValue(
    platform,
    metric,
    groupMetrics,
    currency ?? 'USD'
  );

  return (
    <div className="flex w-full flex-col gap-6 rounded-xl border border-solid border-purple-200 p-4 shadow-sm">
      <Heading className="text-md font-semibold text-[#917DD2]">
        {label}
      </Heading>
      <Text className="text-3xl font-semibold text-primary">
        {parsedValue ? parsedValue : '-'}
      </Text>
    </div>
  );
}

function AdBreakdownInformation({
  adGroup,
  expression,
  comparisonId,
}: {
  adGroup: InsightsAdGroup;
  expression?: InsightsFilter[] | InsightsFilterExpression;
  comparisonId?: string;
}) {
  const { accountUuid, reportUuid } = useParams();
  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );
  const { forTimePeriod, attributionWindow } =
    useInsightsSearchParams().getParsedValues();
  const filter = useInsightsFilter();
  const sort = useInsightsSort();
  const group = useInsightsGroup();

  const comparisonReportQuery =
    trpc.insightsAds.getManyAdsFromComparisonReport.useInfiniteQuery(
      {
        reportUuid: reportUuid ?? '',
        comparisonId: comparisonId ?? '',
        count: INSIGHTS_PAGE_TAKE_LIMIT,
      },
      {
        enabled: !!reportUuid && !!isSharedComparisonReport && !!comparisonId,
        getNextPageParam,
        keepPreviousData: true,
        trpc: { abortOnUnmount: true },
      }
    );

  const reportQuery = trpc.insightsAds.getManyAdsFromReport.useInfiniteQuery(
    {
      uuid: reportUuid ?? '',
      count: INSIGHTS_PAGE_TAKE_LIMIT,
      ungroup: {
        group: group ?? '',
        value: adGroup.name,
      },
      sort,
    },
    {
      // If user is authenticated or user is viewing a shared report
      enabled: !!reportUuid && !!isSharedReport && !!group,
      getNextPageParam,
      keepPreviousData: true,
      trpc: { abortOnUnmount: true },
    }
  );

  const accountQuery = trpc.insightsAds.getManyAds.useInfiniteQuery(
    {
      accountUuid: accountUuid ?? '',
      filter: expression && !group ? expression : filter,
      sort,
      forTimePeriod,
      count: INSIGHTS_PAGE_TAKE_LIMIT,
      attributionWindow,
      ungroup:
        expression && !group
          ? undefined
          : {
              group: group ?? '',
              value: adGroup.group,
            },
    },
    {
      // If user is authenticated or user is viewing a shared report
      enabled:
        !isSharedReport &&
        !isSharedComparisonReport &&
        !!accountUuid &&
        (!!group || !!expression),
      getNextPageParam,
      keepPreviousData: true,
      trpc: { abortOnUnmount: true },
    }
  );

  const adsQuery = isSharedComparisonReport
    ? comparisonReportQuery
    : isSharedReport
    ? reportQuery
    : accountQuery;

  const ads = adsQuery.data?.pages.flat() as Array<
    InsightsAd | InsightsAdGroup
  >;

  const onLoadMore = async () => {
    if (!adsQuery.isLoading && !adsQuery.isFetching && !adsQuery.isError) {
      return adsQuery.fetchNextPage();
    }
  };

  return (
    <>
      <AdBreakdownWizardCards ads={ads} isLoading={adsQuery.isLoading} />
      <AdBreakdownTable
        ads={ads}
        isLoading={adsQuery.isLoading}
        isFetchingNextPage={adsQuery.isFetchingNextPage}
        hasNextPage={!!adsQuery.hasNextPage}
        onLoadMore={onLoadMore}
      />
    </>
  );
}

type WizardHighScore = {
  name: string;
  score: number;
  assets: Array<InsightsAdAsset>;
  ad: InsightsAd;
  creativeType: InsightsAdCreativeType;
  uuid: string;
};

function AdBreakdownWizardCards({
  ads,
  isLoading,
}: {
  ads: Array<InsightsAd | InsightsAdGroup>;
  isLoading: boolean;
}) {
  const { LL } = useI18nContext();

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 pt-8">
        <Heading className="text-base font-semibold text-primary">
          {LL.insights.groupBreakdown.wizardScoreTitle()}
        </Heading>
        <div className="flex items-center gap-4">
          {INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS.map((metric) => (
            <div
              key={metric}
              className="h-[455px] w-full animate-pulse rounded-xl border border-solid border-purple-200 bg-gray-50 shadow"
            />
          ))}
        </div>
      </div>
    );
  }

  if (!isLoading && !ads) return null;

  const wizardHighScores = (ads as Array<InsightsAd>)?.reduce(
    (acc: Record<string, WizardHighScore>, curr) => {
      for (const metric of INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS) {
        if (acc[metric]) {
          const entryScore = acc[metric].score;
          const currScore = curr.metrics[
            metric as InsightsFacebookWizardMetric
          ] as number;

          if (currScore != null && currScore > entryScore) {
            acc[metric] = {
              name: curr.name,
              score:
                (curr.metrics[
                  metric as InsightsFacebookWizardMetric
                ] as number) ?? 0,
              ad: curr,
              assets: curr.creative?.assets ?? [],
              creativeType: curr.creative?.creativeType,
              uuid: curr.uuid,
            };
          }
        } else {
          acc[metric] = {
            name: curr.name,
            score:
              (curr.metrics[
                metric as InsightsFacebookWizardMetric
              ] as number) ?? 0,
            ad: curr,
            assets: curr.creative?.assets ?? [],
            creativeType: curr.creative?.creativeType,
            uuid: curr.uuid,
          };
        }
      }

      return acc;
    },
    {}
  );

  const hasNoScores = Object.values(wizardHighScores).every((ws) => !ws.score);

  if (!wizardHighScores || hasNoScores) return null;

  return (
    <div className="flex flex-col gap-4 pt-8">
      <Heading className="text-base font-semibold text-primary">
        Top ads by Wizard Scores
      </Heading>

      <div className="grid grid-cols-3 gap-4">
        {INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS.map((metric) => (
          <WizardScoreCard
            key={metric}
            ad={wizardHighScores[metric].ad}
            name={wizardHighScores[metric].name}
            score={wizardHighScores[metric].score}
            assets={wizardHighScores[metric].assets}
            creativeType={wizardHighScores[metric].creativeType}
            uuid={wizardHighScores[metric].uuid}
            wizardMetric={metric}
          />
        ))}
      </div>
    </div>
  );
}

interface WizardScoreCardProps extends WizardHighScore {
  wizardMetric: InsightsFacebookWizardMetric;
}

function WizardScoreCard({
  ad,
  name,
  score,
  wizardMetric,
}: WizardScoreCardProps) {
  const { LL } = useI18nContext();

  let colourRange;

  if (!score) return null;

  const wizardScore = Math.round(score);

  const countOfTotalBars = 50;
  const barIncrements = 180 / countOfTotalBars;
  const countOfColouredBars = Math.ceil(countOfTotalBars * (score / 100)) + 1; // +1 for the 0 deg line

  switch (true) {
    case wizardScore < 50:
      colourRange = INSIGHTS_WIZARD_TACOMETER_RED_COLOURS;
      break;
    case wizardScore >= 50 && wizardScore < 70:
      colourRange = INSIGHTS_WIZARD_TACOMETER_YELLOW_COLOURS;
      break;
    case wizardScore >= 70:
      colourRange = INSIGHTS_WIZARD_TACOMETER_GREEN_COLOURS;
      break;
    default:
      break;
  }

  const label =
    INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_REFERENCE[wizardMetric]?.label;
  const hasScore = score > 0;

  const tooltipMap = {
    wizardHookScore: LL.insights.groupBreakdown.emptyHookScore(),
    wizardHoldScore: LL.insights.groupBreakdown.emptyHoldScore(),
    wizardClickScore: LL.insights.groupBreakdown.emptyClickScore(),
    wizardBuyScore: LL.insights.groupBreakdown.emptyBuyScore(),
  };

  if (!label) return null;

  return (
    <div className="flex flex-col items-center gap-4 overflow-hidden rounded-xl border border-solid border-purple-200 px-3 pb-4 pt-3 shadow">
      <WizardMediaPreview ad={ad} score={score} />

      <Heading className="text-md grow self-start overflow-hidden font-semibold text-primary">
        {hasScore ? name : '--'}
      </Heading>

      <div className="relative flex w-52 flex-col justify-center gap-3 pt-2">
        <div className="relative flex h-[100px] w-full flex-col items-center justify-end rounded-md">
          {Array.from({ length: countOfTotalBars + 1 }).map((_, index) => {
            return (
              <i
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="absolute bottom-1 h-full w-0.5 origin-bottom"
                style={{
                  transform: `rotate(${270 + index * barIncrements}deg)`,
                  backgroundColor:
                    index < countOfColouredBars
                      ? lerpColor(colourRange[0], colourRange[1], index * 0.02)
                      : '#E5E7EB',
                }}
              />
            );
          })}
          <div className="absolute bottom-0 flex h-[90%] w-[85%] flex-col items-center justify-end gap-1 rounded-t-full bg-white">
            <span className="text-3xl font-semibold text-primary">
              {hasScore ? wizardScore : '--'}
            </span>
            <div className="flex items-center gap-1">
              <Icon className="h-3.5 w-3.5 text-[#B4A2EF]">
                <MagicBriefBolt />
              </Icon>

              {score > 0 ? (
                <WizardScoreTooltip
                  score={Number(score)}
                  type={wizardMetric as InsightsFacebookWizardMetric}
                >
                  <Button className="text-xs font-medium text-primary">
                    {label}
                  </Button>
                </WizardScoreTooltip>
              ) : (
                <Tooltip delay={0}>
                  <Button className="text-xs font-medium text-primary">
                    {label}
                  </Button>
                  <div className="relative max-w-60 p-2">
                    <span className="text-wrap">
                      {tooltipMap[wizardMetric]}
                    </span>
                    <WizardScoreInfoLink iconStyles="size-4 absolute -top-1 -right-2" />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const columnHelper =
  createColumnHelper<ArrayElement<Array<InsightsAd | InsightsAdGroup>>>();

interface AdBreakdownTableProps {
  ads: Array<InsightsAd | InsightsAdGroup>;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
}

function AdBreakdownTable({
  ads,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
}: AdBreakdownTableProps) {
  if (isLoading) {
    return (
      <div className="flex h-[400px] w-full grow animate-pulse flex-col rounded-xl border border-solid border-purple-200 bg-gray-50 shadow" />
    );
  }

  return (
    <div className="relative my-4 flex h-[500px] w-full flex-auto overflow-auto rounded-xl border border-solid border-purple-200 bg-white">
      <AdBreakdownTableContent
        ads={ads}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onLoadMore={onLoadMore}
      />
    </div>
  );
}

function AdBreakdownTableContent({
  ads,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
}: {
  ads: Array<InsightsAd | InsightsAdGroup>;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
}) {
  const { LL } = useI18nContext();
  const { accountUuid } = useParams();
  const { accountCustomEvents, accountCustomConversions, currency } =
    useInsightsAdAccount({ accountUuid });
  const sort = useInsightsSort();
  const tableMetrics = useInsightsDisplay();
  const platform = useInsightsPlatform();

  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const customTrigger = ({ children }: PropsWithChildren) => (
    <span className="text-xs font-semibold text-primary">{children}</span>
  );

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: () => (
        <span className="text-xs font-semibold text-primary">
          {LL.labels.name()}
        </span>
      ),
      size: 250,
      maxSize: 250,
      cell: (cell) => {
        const item = cell.row.original;

        return (
          <div className="flex items-center gap-3 overflow-hidden">
            {item.type === 'ad' && <TableMediaPreview ad={item} />}
            <span
              className="truncate text-sm font-medium text-black"
              style={{ maxWidth: cell.column.getSize() }}
            >
              {item.name}
            </span>
          </div>
        );
      },
    }),

    ...tableMetrics.map((metric) => {
      return columnHelper.accessor(
        (row) => getValueForMetric(metric, row.metrics),
        {
          id: metric,
          size: 150,
          header: (header) => (
            <InsightsMetricLabelTooltip
              platform={platform}
              metric={metric}
              onPress={header.column.getToggleSortingHandler()}
              customEvents={accountCustomEvents ?? []}
              customConversions={accountCustomConversions ?? []}
              customTrigger={customTrigger}
            />
          ),
          cell: (cell) => {
            const parsedValue = getMetricValue(
              platform,
              cell.column.id,
              cell.row.original.metrics,
              currency ?? 'USD'
            );

            return (
              <span className="text-sm font-normal text-black">
                {parsedValue ? parsedValue : LL.insights.metrics.none()}
              </span>
            );
          },
        }
      );
    }),
  ];

  const table = useReactTable({
    data: ads ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sort,
    },
    initialState: {
      columnPinning: {
        left: ['name'],
      },
    },
  });

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        setIsScrolled(containerRef.current.scrollLeft > 0);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <ResizableTableContainer
      ref={containerRef}
      className="relative box-border w-full flex-auto overflow-auto bg-white"
    >
      <InfiniteScroll
        pageStart={0}
        className="flex w-full flex-auto"
        initialLoad={false}
        loadMore={onLoadMore}
        hasMore={hasNextPage}
        useWindow={false}
        threshold={1200}
      >
        <table
          className="border-separate border-spacing-0 rounded-xl bg-white text-purple-800"
          style={{
            width: table.getTotalSize(),
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isPinned = header.column.getIsPinned();
                  const styles: CSSProperties = {
                    width: header.getSize(),
                    position: 'sticky',
                    zIndex: isPinned === 'left' ? 3 : 2,
                    top: 0,
                    left:
                      isPinned === 'left'
                        ? `${header.column.getStart('left')}px`
                        : undefined,
                  };

                  return (
                    <th
                      key={header.id}
                      className={cn(
                        'border-b border-solid border-b-purple-200 bg-purple-50 px-4 py-2.5 text-start',
                        isScrolled && isPinned
                          ? 'border-r border-r-purple-200'
                          : ''
                      )}
                      style={styles}
                    >
                      <div className="flex w-full items-center gap-2 text-nowrap">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}

                        {header.column.getIsSorted() && (
                          <Icon
                            className={cn(
                              'size-2.5',
                              header.column.getIsSorted() === 'desc'
                                ? 'rotate-180'
                                : ''
                            )}
                          >
                            <ArrowUp />
                          </Icon>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const isPinned = cell.column.getIsPinned();
                  const styles: CSSProperties = {
                    position: isPinned ? 'sticky' : 'relative',
                    width: cell.column.getSize(),
                    zIndex: isPinned ? 1 : 0,
                    left:
                      isPinned === 'left'
                        ? `${cell.column.getStart('left')}px`
                        : undefined,
                  };

                  return (
                    <td
                      key={cell.id}
                      className={cn(
                        'border-b border-solid border-b-purple-200 bg-white px-3 py-2 align-middle',
                        isScrolled && isPinned
                          ? 'border-r border-r-purple-200'
                          : ''
                      )}
                      style={styles}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}

            {isFetchingNextPage && (
              <tr className="h-[49px]">
                <td className="sticky left-0 flex items-center p-4">
                  <div className="flex gap-2">
                    <Spinner />
                    <span className="text-sm font-semibold">
                      {LL.loading()}
                    </span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </InfiniteScroll>
    </ResizableTableContainer>
  );
}

interface TableMediaPreviewProps {
  ad: InsightsAd;
  className?: string;
}

function TableMediaPreview({ ad, className }: TableMediaPreviewProps) {
  const { recordEvent } = useNewAnalyticsEvent();
  const setInsightsAdPreview = useSetAtom(insightsAdPreviewAtom);
  const assets = ad.creative.assets ?? [];

  if (!assets.length)
    return (
      <div
        className="size-8 rounded-md"
        style={{ backgroundImage: `url(${Checkerboard})` }}
      />
    );

  const asset = assets[0];
  const creativeType = ad.creative?.creativeType;
  const uuid = ad.uuid;

  const handleOpenAdPreview = () => {
    void recordEvent({
      target: 'Insights Preview',
      action: 'Opened',
      metadata: {
        object_url: `${window.location.href}?libraryUuid=${uuid}`,
      },
    });

    setInsightsAdPreview({
      assets,
      creativeType,
      uuid,
      portalId: 'insights-ad-breakdown',
    });
  };

  return (
    <Button
      className={cn(
        'group/thumbnail relative flex aspect-square size-8 items-center rounded-md bg-gray-50 hover:cursor-zoom-in focus:outline-none',
        className
      )}
      onPress={handleOpenAdPreview}
    >
      <Image
        className="size-[inherit] rounded-md object-cover"
        src={
          asset.cachedThumbnailUrl ??
          asset.thumbnailUrl ??
          (asset.mediaType === 'image' ? asset.cachedUrl ?? asset.url : null) ??
          ''
        }
        fallbackSrc={asset.thumbnailUrl ?? ''}
        alt="thumbnail"
      />
    </Button>
  );
}

interface WizardMediaPreviewProps extends TableMediaPreviewProps {
  score: number;
}

function WizardMediaPreview({ ad, score, className }: WizardMediaPreviewProps) {
  const { recordEvent } = useNewAnalyticsEvent();
  const setInsightsAdPreview = useSetAtom(insightsAdPreviewAtom);
  const assets = ad.creative.assets ?? [];
  const hasScore = score > 0;

  const asset = assets[0] ?? null;
  const creativeType = ad.creative?.creativeType;
  const uuid = ad.uuid;

  const url = asset?.cachedUrl || asset?.url || '';
  const isCachedVideo = asset?.mediaType === 'video' && url != null;
  const isIframeVideo =
    asset?.mediaType === 'video' && url == null && asset.embedHtml != null;

  const handleOpenAdPreview = () => {
    void recordEvent({
      target: 'Insights Preview',
      action: 'Opened',
      metadata: {
        object_url: `${window.location.href}?libraryUuid=${uuid}`,
      },
    });

    setInsightsAdPreview({
      assets,
      creativeType,
      uuid,
      portalId: 'insights-ad-breakdown',
    });
  };

  if (!assets.length || !hasScore)
    return (
      <div
        className="aspect-square h-auto w-full rounded-md"
        style={{ backgroundImage: `url(${Checkerboard})` }}
      />
    );

  return (
    <div className="flex-centered relative flex aspect-square overflow-hidden rounded-md">
      <Button
        className={cn(
          'group/thumbnail relative flex aspect-square w-full items-center rounded-md bg-gray-50 hover:cursor-zoom-in focus:outline-none',
          className
        )}
        onPress={handleOpenAdPreview}
      >
        {(isCachedVideo || isIframeVideo) && (
          <Icon
            className={cn(
              'absolute left-1/2 top-1/2 z-[1] size-14 -translate-x-1/2 -translate-y-1/2 rounded-full text-white drop-shadow-lg transition-all duration-300',
              'group-hover/thumbnail:bg-white group-hover/thumbnail:text-primary'
            )}
          >
            <PlayCircle className="size-14" />
          </Icon>
        )}

        <Image
          className="w-full rounded-md"
          src={
            asset?.cachedThumbnailUrl ??
            asset?.thumbnailUrl ??
            (asset?.mediaType === 'image'
              ? asset?.cachedUrl ?? asset?.url
              : null) ??
            ''
          }
          fallbackSrc={asset?.thumbnailUrl ?? ''}
          alt="thumbnail"
        />
      </Button>
    </div>
  );
}
