import { useEffect, useMemo, useState } from 'react';
import { parseDate } from '@internationalized/date';
import {
  convertDatePresetToDateRange,
  INSIGHTS_FACEBOOK_SELECTABLE_DATE_PRESETS,
  INSIGHTS_TIKTOK_SELECTABLE_DATE_PRESETS,
  InsightsFacebookSelectableDatePreset,
} from '@magicbrief/common';
import { ForTimePeriodInput } from '@magicbrief/server/src/insights/types';
import { DateValue, ListBox, ListBoxItem } from 'react-aria-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Calendar from 'src/assets/svgicons/duocolor/calendar.svg';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import { AriaButton } from 'src/components/Button/Button';
import {
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  CalendarHeading,
  RangeCalendar,
} from 'src/components/Calendar/AriaCalendar';
import { Icon } from 'src/components/Icon';
import { UpgradeModalV2 } from 'src/components/Modals/UpgradeModalV2/UpgradeModalV2';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from 'src/components/Popover/AriaPopover';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import dayjs from 'src/lib/dayjs';
import { useEntitlementQuotas } from 'src/utils/useEntitlementQuotas';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { getTimePeriodLabel } from '../../util/getTimePeriodLabel';
import {
  useInsightsAdAccount,
  useRefetchData,
} from '../../util/useInsightsQueries';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';

const facebookDatePresets: (InsightsFacebookSelectableDatePreset | 'custom')[] =
  [
    ...(Object.keys(
      INSIGHTS_FACEBOOK_SELECTABLE_DATE_PRESETS
    ) as InsightsFacebookSelectableDatePreset[]),
    'custom' as const,
  ];

const tiktokDatePresets = [
  ...(Object.keys(
    INSIGHTS_TIKTOK_SELECTABLE_DATE_PRESETS
  ) as InsightsFacebookSelectableDatePreset[]),
  'custom' as const,
];

function getDatePresetsForPlatform(platform: 'facebook' | 'tiktok') {
  switch (platform) {
    case 'facebook':
      return facebookDatePresets;
    case 'tiktok':
      return tiktokDatePresets;
  }
}

/** @todo remove the casts to DateValue here, weird type issues due to private field on CalendarDate type */
function InsightsFilterTimePeriodMenuV2() {
  const { LL } = useI18nContext();
  const { getParsedValues, params } = useInsightsSearchParams();
  const { since, until, timePeriod, forTimePeriod } = getParsedValues();
  const { refetch, isLoading } = useRefetchData();
  const { accountUuid, reportUuid } = useParams();
  const { hasEntitlement } = useEntitlementQuotas('insights_ad_account');
  const { recordEvent } = useNewAnalyticsEvent();

  const { adAccount } = useInsightsAdAccount({
    accountUuid,
  });

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [currentDatePreset, setCurrentDatePreset] = useState<
    InsightsFacebookSelectableDatePreset | 'custom'
  >(timePeriod);

  const [start, setStart] = useState<DateValue | null>(
    timePeriod !== 'custom'
      ? (parseDate(
          convertDatePresetToDateRange(timePeriod).since.format('YYYY-MM-DD')
        ) as unknown as DateValue)
      : since
      ? (parseDate(since) as unknown as DateValue)
      : null
  );
  const [end, setEnd] = useState<DateValue | null>(
    timePeriod !== 'custom'
      ? (parseDate(
          convertDatePresetToDateRange(timePeriod).until.format('YYYY-MM-DD')
        ) as unknown as DateValue)
      : until
      ? (parseDate(until) as unknown as DateValue)
      : null
  );
  const [focusedValue, setFocusedValue] = useState<DateValue>();

  const isValid = useMemo(
    () => currentDatePreset != null && start != null && end != null,
    [currentDatePreset, start, end]
  );

  const [open, setOpen] = useState(false);

  const datePresets = adAccount.data
    ? getDatePresetsForPlatform(adAccount.data?.platform)
    : [];

  // This is to syncronise the Calendar UI with the date preset for Reports or from the default date preset
  useEffect(() => {
    if (reportUuid || timePeriod) {
      setCurrentDatePreset(timePeriod);
      setStart(
        timePeriod !== 'custom'
          ? (parseDate(
              convertDatePresetToDateRange(timePeriod).since.format(
                'YYYY-MM-DD'
              )
            ) as unknown as DateValue)
          : since
          ? (parseDate(since) as unknown as DateValue)
          : null
      );
      setEnd(
        timePeriod !== 'custom'
          ? (parseDate(
              convertDatePresetToDateRange(timePeriod).until.format(
                'YYYY-MM-DD'
              )
            ) as unknown as DateValue)
          : until
          ? (parseDate(until) as unknown as DateValue)
          : null
      );
    }
  }, [reportUuid, since, timePeriod, until]);

  return (
    <>
      <PopoverTrigger>
        <AriaButton
          variant="white"
          onPress={() => {
            if (hasEntitlement) {
              setOpen(true);
            } else {
              setShowUpgradeModal(true);
            }
          }}
          icon={
            <Icon className="size-5.5 text-primary">
              <Calendar />
            </Icon>
          }
        >
          <div className="flex items-center justify-between gap-3">
            <span className="hidden sm:block sm:whitespace-nowrap">
              {getTimePeriodLabel(forTimePeriod, LL)}
            </span>
            <Icon className="size-5 text-primary">
              <ChevronDown />
            </Icon>
          </div>
        </AriaButton>
        <Popover offset={8} isOpen={open} onOpenChange={setOpen}>
          <PopoverDialog className="flex max-h-[470px] flex-row p-0">
            <div className="grow overflow-auto border-r border-solid border-r-gray-200 px-4 py-3">
              <ListBox aria-label="time period options">
                {datePresets.map((datePreset) => {
                  const isSelected = datePreset === currentDatePreset;

                  return (
                    <ListBoxItem
                      className={cn(
                        'w-full cursor-pointer text-nowrap rounded-lg px-4 py-2.5 text-start text-sm font-medium text-primary outline-none',
                        isSelected && 'bg-purple-50 font-semibold'
                      )}
                      key={datePreset}
                      textValue={LL.insights.timePeriod[
                        datePreset as keyof typeof LL.insights.timePeriod
                      ]()}
                      onAction={() => {
                        setCurrentDatePreset(datePreset);
                        if (datePreset === 'custom') {
                          setStart(
                            since
                              ? (parseDate(since) as unknown as DateValue)
                              : null
                          );
                          setEnd(
                            until
                              ? (parseDate(until) as unknown as DateValue)
                              : null
                          );
                          setFocusedValue(
                            until
                              ? (parseDate(until) as unknown as DateValue)
                              : undefined
                          );
                        } else {
                          const { since, until } =
                            convertDatePresetToDateRange(datePreset);

                          setStart(
                            parseDate(
                              since.format('YYYY-MM-DD')
                            ) as unknown as DateValue
                          );
                          setEnd(
                            parseDate(
                              until.format('YYYY-MM-DD')
                            ) as unknown as DateValue
                          );
                          setFocusedValue(
                            parseDate(
                              until.format('YYYY-MM-DD')
                            ) as unknown as DateValue
                          );
                        }
                        void recordEvent({
                          action: 'Time Period Changed',
                          target: 'Insights Time Period',
                          metadata: {
                            since: start?.toString(),
                            until: end?.toString(),
                            currentDatePreset,
                          },
                        });
                      }}
                    >
                      {LL.insights.timePeriod[
                        datePreset as keyof typeof LL.insights.timePeriod
                      ]()}
                    </ListBoxItem>
                  );
                })}
              </ListBox>
            </div>
            <div className="flex flex-col justify-between">
              <div className="grow px-6 py-5">
                <RangeCalendar
                  className="w-fit"
                  value={start && end && { start, end }}
                  defaultValue={start && end && { start, end }}
                  focusedValue={focusedValue}
                  onFocusChange={setFocusedValue}
                  onChange={(value) => {
                    /** @todo when the month changes out of view, switch to that month (is this a bug in RAC?) */
                    /** @todo look up Facebook docs for actual earliest date here and make sure earlier since is set to that */
                    /** @todo do this check in the API layer also https://linear.app/magicbrief/issue/ENG-1655 */

                    const todayMs = dayjs().endOf('day').valueOf();
                    const isValidEndDate =
                      value?.end &&
                      dayjs(value.end.toString()).valueOf() < todayMs;

                    if (!isValidEndDate) {
                      setStart(start);
                      setEnd(end);
                      toast.error('You cannot select a date in the future');
                      return;
                    }

                    const twoYearsAgoMs = dayjs()
                      .subtract(2, 'years')
                      .startOf('day')
                      .valueOf();

                    const isValidStartDate =
                      value.start &&
                      dayjs(value.start.toString()).valueOf() > twoYearsAgoMs;

                    if (!isValidStartDate) {
                      setStart(start);
                      setEnd(end);
                      toast.error(
                        'You cannot select a date that far in the past'
                      );
                      return;
                    }

                    setCurrentDatePreset('custom');
                    setStart(value.start);
                    setEnd(value.end);
                  }}
                >
                  <CalendarHeading />
                  <CalendarGrid>
                    <CalendarGridHeader>
                      {(day) => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
                    </CalendarGridHeader>
                    <CalendarGridBody>
                      {(date) => <CalendarCell date={date} />}
                    </CalendarGridBody>
                  </CalendarGrid>
                </RangeCalendar>
              </div>
              <div className="flex justify-end gap-2 border-t border-solid border-t-gray-200 p-3">
                <AriaButton
                  variant="secondary"
                  className="rounded-lg border border-solid border-gray-300 px-4 py-2.5 text-sm font-semibold text-primary"
                  isDisabled={isLoading}
                  onPress={() => setOpen(false)}
                >
                  {LL.cancel()}
                </AriaButton>
                <AriaButton
                  variant="primary"
                  className="rounded-lg bg-primary px-4 py-2.5 text-sm font-semibold text-white"
                  loading={isLoading}
                  isDisabled={!isValid}
                  onPress={async () => {
                    if (currentDatePreset === 'custom') {
                      const now = dayjs();
                      const updatedUntil = end ? dayjs(end.toString()) : now;
                      const updatedSince = start
                        ? dayjs(start.toString())
                        : updatedUntil.subtract(1, 'week');

                      params.setMany({
                        timePeriod: currentDatePreset,
                        since: updatedSince.format('YYYY-MM-DD'),
                        until: updatedUntil.format('YYYY-MM-DD'),
                      });

                      const forTimePeriod: ForTimePeriodInput = {
                        datePreset: 'custom',
                        since: updatedSince.format('YYYY-MM-DD'),
                        until: updatedUntil.format('YYYY-MM-DD'),
                      };

                      if (adAccount?.data?.uuid) {
                        refetch({
                          adAccountUuid: adAccount?.data?.uuid,
                          forTimePeriod: forTimePeriod,
                        });
                      }
                    } else {
                      const forTimePeriod: ForTimePeriodInput = {
                        datePreset: currentDatePreset,
                      };
                      if (adAccount?.data?.uuid) {
                        refetch({
                          adAccountUuid: adAccount?.data?.uuid,
                          forTimePeriod: forTimePeriod,
                        });
                      }

                      params.setMany({
                        timePeriod: currentDatePreset,
                        since: undefined,
                        until: undefined,
                      });
                    }

                    setOpen(false);
                  }}
                >
                  {LL.save()}
                </AriaButton>
              </div>
            </div>
          </PopoverDialog>
        </Popover>
      </PopoverTrigger>
      <UpgradeModalV2
        isOpen={showUpgradeModal}
        close={() => setShowUpgradeModal(false)}
        prompt="to unlock insights"
      />
    </>
  );
}

export default InsightsFilterTimePeriodMenuV2;
