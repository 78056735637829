import { Component, PropsWithChildren, FC } from 'react';
import * as Sentry from '@sentry/react';
import DefaultFallback from './components/DefaultFallback';

type State = {
  error?: Error;
};

type FallbackProps = {
  error: Error;
};

type Props = PropsWithChildren<{
  fallback?: FC<FallbackProps>;
}>;

export default class MagicBriefErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.captureException(error, {
      level: 'fatal',
      contexts: {
        opts: {
          componentStack: errorInfo.componentStack,
        },
      },
    });
  }

  render() {
    if (this.state?.error) {
      const Fallback = this.props.fallback;
      if (Fallback) {
        return <Fallback error={this.state.error} />;
      }
      // Return default fallback
      return <DefaultFallback error={this.state.error} />;
    }
    return this.props.children;
  }
}
