import { useEffect } from 'react';
import { isProdDeploy } from '@magicbrief/common';
import {
  browserProfilingIntegration,
  browserTracingIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { sentryConfigs } from 'src/configs';
import appConfig from '../configs/app';

export function initSentry() {
  const { env, debug, deployment, release } = appConfig;

  if (env === 'development' && !debug) {
    return;
  }

  init({
    dsn: sentryConfigs.dsn,
    release,
    environment: deployment,
    debug,
    /** @see {@link https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/ default integrations here} */
    integrations: (defaultIntegrations) => [
      ...defaultIntegrations,
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
      browserTracingIntegration(),
      browserProfilingIntegration(),
      extraErrorDataIntegration(),
      httpClientIntegration(),
    ],
    tracesSampleRate: isProdDeploy(import.meta.env) ? 0.1 : 1,
    replaysSessionSampleRate: isProdDeploy(import.meta.env) ? 0.1 : 1,
    replaysOnErrorSampleRate: isProdDeploy(import.meta.env) ? 0.1 : 1,
    tracePropagationTargets: [
      /^(http|https):\/\/.*localhost.*/,
      /^(http|https):\/\/.*magicbrief\.com$/,
    ],
  });
}
