import {
  computeActionMetricNameWithSubvalue,
  computeNameForCustomEvent,
  getMetricFormat,
  INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE,
  INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE,
  INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM,
  InsightsFilterV2,
  parseInsightsFacebookActionMetric,
} from '@magicbrief/common';
import { GetFacebookAdAccountResponse } from '../../../types/insights';
import { useInsightsAdAccount } from './useInsightsQueries';
import type { InsightsAdMetrics } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

const swapLookupKey = (metric: string): string => {
  if (metric === 'adHeadline') {
    return 'insights_ad_facebook_ad_headline';
  }
  if (metric === 'creativeType') {
    return 'insights_creative_facebook_creative_type';
  }
  if (metric === 'landingPage') {
    return 'insights_ad_facebook_landing_page';
  }
  if (metric === 'adCopy') {
    return 'insights_ad_facebook_ad_copy';
  }
  return metric;
};

export function getValueForMetric(metric: string, metrics: InsightsAdMetrics) {
  const metricKey = swapLookupKey(metric);
  const value = metrics[metricKey];

  if (typeof value === 'object' && !(value instanceof Date)) {
    return null;
  }

  return metrics[metricKey];
}

export function parseValueForMetric(
  platform: 'facebook' | 'tiktok',
  metric: string,
  value: string | number | Date,
  currency: GetFacebookAdAccountResponse['currency'],
  formatOptions?: Partial<{
    date: Intl.DateTimeFormatOptions;
  }> &
    Partial<
      Record<
        Exclude<ReturnType<typeof getMetricFormat>, 'date'>,
        Intl.NumberFormatOptions
      >
    >
): string | null {
  const format = getMetricFormat(platform, metric);
  const locale = window.navigator.languages
    ? window.navigator.languages[0]
    : window.navigator.language;

  if (metric === 'effectiveStatus') {
    return value === 'ACTIVE' ? 'Active' : 'Inactive';
  }

  switch (format) {
    case 'text':
      return `${value}`;
    case 'date':
      return Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        ...formatOptions?.[format],
      }).format(typeof value === 'string' ? new Date(value) : value);
    case 'numeric':
      return typeof value === 'number'
        ? Intl.NumberFormat(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            ...formatOptions?.[format],
          }).format(value)
        : '';
    case 'currency':
      return typeof value === 'number'
        ? Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            ...formatOptions?.[format],
          }).format(value)
        : '';
    case 'percentage':
      return typeof value === 'number'
        ? Intl.NumberFormat(locale, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            ...formatOptions?.[format],
          }).format(value > 0 ? value / 100 : value)
        : '';
    default:
      return null;
  }
}

export function getMetricValue(
  platform: 'facebook' | 'tiktok',
  metric: string,
  metrics: InsightsAdMetrics,
  currency: GetFacebookAdAccountResponse['currency']
) {
  const value = getValueForMetric(metric, metrics);

  if (value == null || Array.isArray(value)) {
    return null;
  }

  const parsedValue = parseValueForMetric(platform, metric, value, currency);

  return parsedValue;
}

function computeNameForCustomConversion(
  event: string,
  customConversions: Array<{ facebookId: string; name: string }>
) {
  return (
    customConversions.find(
      (x) => `offsite_conversion.custom.${x.facebookId}` === event
    )?.name ?? null
  );
}

export function getMetricSynonyms(
  platform: 'facebook' | 'tiktok',
  metric: string
) {
  return (
    INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM[platform][
      metric as keyof (typeof INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM)[typeof platform]
    ]?.synonyms ?? ''
  );
}

export function getMetricDefinitions(
  platform: 'facebook' | 'tiktok',
  metric: string
) {
  return (
    INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM[platform][
      metric as keyof (typeof INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM)[typeof platform]
    ]?.definition ?? null
  );
}

export function getMetricLabelAsText(
  platform: 'facebook' | 'tiktok',
  metric: string,
  customEvents: string[] | null | undefined,
  customConversions:
    | Array<{ facebookId: string; name: string }>
    | null
    | undefined
): string | null {
  const knownValue =
    INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM[platform][
      metric as keyof (typeof INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM)[typeof platform]
    ]?.label ?? null;

  if (knownValue) return knownValue;

  if (metric.startsWith('action:')) {
    const builtinLabel =
      INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE[
        metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE
      ]?.label ?? null;

    if (builtinLabel) return builtinLabel;

    const match = parseInsightsFacebookActionMetric(metric);
    if (!match) {
      return null;
    }

    const customConversionLabel = computeNameForCustomConversion(
      match.metric,
      customConversions ?? []
    );

    if (customConversionLabel)
      return computeActionMetricNameWithSubvalue(
        customConversionLabel,
        match.subvalue
      );

    const customEventLabel = computeNameForCustomEvent(
      match.metric,
      customEvents ?? []
    );

    if (customEventLabel)
      return computeActionMetricNameWithSubvalue(
        customEventLabel,
        match.subvalue
      );

    return null;
  }

  if (metric.startsWith('custom:')) {
    return metric.split('custom:')?.[1];
  }

  return (
    INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE[
      metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE
    ]?.label ?? null
  );
}

export function useGetMetricLabelAsText({
  accountUuid,
  platform,
  metric,
}: {
  accountUuid: string;
  platform: 'facebook' | 'tiktok';
  metric: string;
}) {
  const { accountCustomEvents, accountCustomConversions } =
    useInsightsAdAccount({ accountUuid });

  return getMetricLabelAsText(
    platform,
    metric,
    accountCustomEvents,
    accountCustomConversions
  );
}

function getMetricHeader(
  platform: 'facebook' | 'tiktok',
  metric: string,
  customEvents: string[] | null | undefined,
  customConversions:
    | Array<{ facebookId: string; name: string }>
    | null
    | undefined
): string | null {
  const knownValue =
    INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM[platform][
      metric as keyof (typeof INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM)[typeof platform]
    ]?.header ?? null;

  if (knownValue) return knownValue;

  if (metric.startsWith('action:')) {
    const builtinLabel =
      INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE[
        metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE
      ]?.header ?? null;

    if (builtinLabel) return builtinLabel;

    const match = parseInsightsFacebookActionMetric(metric);
    if (!match) {
      return null;
    }

    if (customConversions) {
      const customConversionLabel = computeNameForCustomConversion(
        match.metric,
        customConversions
      );

      if (customConversionLabel)
        return computeActionMetricNameWithSubvalue(
          customConversionLabel,
          match.subvalue
        );
    }

    if (customEvents) {
      const customEventLabel = computeNameForCustomEvent(
        match.metric,
        customEvents
      );

      if (customEventLabel)
        return computeActionMetricNameWithSubvalue(
          customEventLabel,
          match.subvalue
        );
    }

    return null;
  }

  if (metric.startsWith('custom:')) {
    return metric.split('custom:')?.[1];
  }

  return null;
}

export function useParseMetric() {
  return {
    getMetricLabelAsText,
    getMetricHeader,
    getMetricDefinitions,
    getMetricSynonyms,
  };
}

export function getMetricFilterValuesAsText(
  platform: 'facebook' | 'tiktok',
  filter: InsightsFilterV2
) {
  if ('field' in filter) {
    if ('value' in filter) {
      return filter.value.toString();
    } else if ('min' in filter) {
      return `${filter.min} and ${filter.max}`;
    } else if ('values' in filter) {
      const possibleMapping =
        INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM[platform][
          filter.field as keyof (typeof INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM)[typeof platform]
        ]?.enumerable;

      return filter.values.reduce(
        (acc, value, index) =>
          `${acc}${
            index === 0
              ? `${
                  !!possibleMapping && value in possibleMapping
                    ? possibleMapping[value]
                    : value
                }`
              : index < filter.values.length - 1
              ? `, ${
                  !!possibleMapping && value in possibleMapping
                    ? possibleMapping[value]
                    : value
                }`
              : ` and ${
                  !!possibleMapping && value in possibleMapping
                    ? possibleMapping[value]
                    : value
                }`
          }`,
        ''
      );
    }
  }
  return null;
}
