import { FC, useState, useRef, useCallback } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@sentry/react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { type HTMLMotionProps, motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { type UserOnboardInputV3 as UserOnboardInput } from '@magicbrief/server/src/user/types';
import { userIndustries, type UserIndustry } from '@magicbrief/common';
import BaseModal from 'src/components/AriaModal/BaseModal';
import { AriaButton } from 'src/components/Button/Button';
import Input from 'src/components/Input';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';
import SegmentedProgressBar from '../SegmentedProgressBar';
import SelectionChip from '../SelectionChip';
import { Select } from '../Select';
import {
  interestsDefaultValues,
  interestsIcons,
  firstStepFields,
  userIndustriesIcons,
  defaultUserIndustries,
  type InterestKey,
} from './constants';

type Props = {
  onComplete: () => void;
};

const STEP_WEIGHT = 100 / firstStepFields.length;

const OnboardingModal: FC<Props> = ({ onComplete }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [step, setStep] = useState(1);
  const direction = useRef<-1 | 0 | 1>(0);
  const user = useUserAndOrganisation();
  const onboard = trpc.user.completeUserOnboardFormV3.useMutation();
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();
  const [displayIndustries, setDisplayIndustries] = useState<UserIndustry[]>(
    defaultUserIndustries
  );

  /**
   * @todo get imports working between server and frontend packages
   */
  const fieldsSchemaObject = {
    displayName: z
      .string({ required_error: LL.onboarding.errors.nameRequired() })
      .trim()
      .min(2, { message: LL.onboarding.errors.displayNameTooShort({ n: 2 }) })
      .max(50, {
        message: LL.onboarding.errors.displayNameTooLong({ n: 50 }),
      })
      .regex(/^[a-zA-Z\-'\s]+$/, {
        message: LL.onboarding.errors.invalidCharacters(),
      }),
    workspaceName: z
      .string({ required_error: LL.onboarding.errors.workspaceNameRequired() })
      .trim()
      .min(2, { message: LL.onboarding.errors.workspaceNameTooShort({ n: 2 }) })
      .max(50, {
        message: LL.onboarding.errors.workspaceNameTooLong({ n: 50 }),
      })
      .regex(/^[a-zA-Z\-'\s]+$/, {
        message: LL.onboarding.errors.invalidCharacters(),
      }),
    interests: z
      .object({
        adInspiration: z.boolean(),
        creativeAnalytics: z.boolean(),
        trackCompetitors: z.boolean(),
        createAndShareBriefs: z.boolean(),
      })
      .refine((data) => Object.values(data).some(Boolean), {
        message: LL.onboarding.errors.interestsRequired(),
      }),
    userIndustry: z.enum(userIndustries),
  } as const;
  const userOnboardInput = z.object(fieldsSchemaObject);

  const defaultValues = {
    displayName: '',
    workspaceName:
      user.data?.organisation.name === 'My Organisation'
        ? ''
        : user.data?.organisation.name ?? '',
    interests: interestsDefaultValues,
  };

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm<UserOnboardInput>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(userOnboardInput),
  });

  // Hiding this for now
  // const mapToIndustry = (input: UserIndustry): string | null => {
  //   switch (input) {
  //     case 'app':
  //       return 'App / Software';
  //     case 'agency':
  //       return null;
  //     case 'beauty':
  //       return 'Beauty / Personal Care';
  //     case 'clothing':
  //       return 'Fashion';
  //     case 'consumerGoods':
  //       return 'Consumer Goods';
  //     case 'education':
  //       return 'Education';
  //     case 'realEstate':
  //       return 'Real Estate';
  //     case 'electronics':
  //       return 'Electronics';
  //     case 'entertainment':
  //       return 'Entertainment';
  //     case 'finance':
  //       return 'Finance';
  //     case 'fitness':
  //       return 'Fitness';
  //     case 'foodBeverage':
  //       return 'Food / Beverage';
  //     case 'games':
  //       return 'Gaming';
  //     case 'healthcare':
  //       return 'Health & Wellness';
  //     case 'home':
  //       return 'Home / Garden';
  //     case 'kids':
  //       return 'Kids';
  //     case 'news':
  //       return 'News / Entertainment';
  //     case 'other':
  //       return 'Other';
  //     case 'sportsOutdoor':
  //       return 'Sports / Outdoor';
  //     case 'services':
  //       return 'Services';
  //     case 'pets':
  //       return 'Pets';
  //     case 'politics':
  //       return null;
  //     case 'transport':
  //       return 'Transport';
  //     case 'travel':
  //       return 'Travel';
  //     case 'travelAccessories':
  //       return 'Travel Accessories';
  //     default:
  //       input satisfies never;
  //       throw new Error('input', input);
  //   }
  // };

  const onSubmit = handleSubmit((data: UserOnboardInput) => {
    onboard.mutate(data, {
      onSuccess: (data) => {
        void trpcUtils.user.getUserAndOrganisation.setData(undefined, data);
        void trpcUtils.adBrands.getForYouBrandSuggestions.invalidate();

        navigate({
          pathname: '/library/discover',
        });

        setShow(false);
      },
      onError: (err, opts) => {
        captureException(err, { contexts: { opts } });
      },
    });
  });

  const isFieldValid = useCallback(
    (field: keyof typeof fieldsSchemaObject) => {
      const fieldSchema = userOnboardInput.pick({
        [field]: true,
      } as Record<keyof typeof fieldsSchemaObject, true>);

      const parse = fieldSchema.safeParse({
        [field]: getValues(field),
      });

      return parse.success;
    },
    [getValues, userOnboardInput]
  );

  async function nextStep() {
    const isValid = await trigger(firstStepFields, { shouldFocus: true });

    if (isValid) {
      direction.current = 1;
      setStep(step + 1);
    }
  }

  const hasEditPermission =
    user.data?.role != null &&
    ['wizard', 'superuser', 'owner', 'admin', 'editor'].includes(
      user.data.role
    );

  const stepAnimationProps: HTMLMotionProps<'div'> = {
    initial: { x: direction.current * 30, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: {
      duration: 0.2,
      bounce: 0,
    },
  };

  const allFields = watch();

  const stepsPercentages = Object.entries(allFields).reduce(
    (acc, [field, value]) => {
      const hasSelectedInterest = Object.values(allFields.interests).some(
        Boolean
      );

      if (field === 'displayName' || field === 'workspaceName') {
        if (typeof value === 'string' && value.length > 2) {
          acc[0] += STEP_WEIGHT;
        }
      }

      if (hasSelectedInterest) {
        acc[0] += STEP_WEIGHT;
      }

      if (
        field === 'userIndustry' &&
        typeof value === 'string' &&
        value.length > 0
      ) {
        acc[1] = 100;
      }

      return acc;
    },
    [0, 0]
  );

  return (
    <BaseModal
      show={show}
      onClose={null}
      afterClose={onComplete}
      modalClassName="bg-white rounded-lg sm:w-auto sm:m-0 text-primary"
      overlayClassName="overflow-y-hidden"
      dialogClassName="w-full sm:w-[478px] p-6 flex flex-col gap-6"
    >
      <SegmentedProgressBar
        className="mb-4"
        stepsPercentages={stepsPercentages}
      />
      <form onSubmit={onSubmit}>
        {/* Step 1 */}
        {step === 1 && (
          <motion.div {...stepAnimationProps} className="space-y-6">
            <h1 className="text-pretty text-2xl font-semibold leading-8">
              {LL.onboarding.step1Title()}
            </h1>
            <div className="space-y-4">
              <Input
                {...register('displayName')}
                error={errors.displayName?.message}
                disabled={isSubmitting}
                label={LL.onboarding.displayName.label()}
                placeholder={LL.onboarding.displayName.placeholder()}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
              <Input
                {...register('workspaceName')}
                error={errors.workspaceName?.message}
                disabled={!hasEditPermission || isSubmitting}
                label={LL.onboarding.workspaceName.label()}
                placeholder={LL.onboarding.workspaceName.placeholder()}
              />
            </div>
            <div className="space-y-3">
              <div>
                <label className="text-xs font-semibold text-primary sm:text-sm">
                  {LL.onboarding.interests.label()}
                </label>
                {errors.interests?.message && (
                  <p className="text-sm text-danger">
                    {errors.interests.message}
                  </p>
                )}
              </div>
              <Controller
                control={control}
                name="interests"
                render={({ field: { name, onChange, value, ...rest } }) => {
                  return (
                    <div className="grid grid-cols-2 gap-4">
                      {(
                        Object.keys(interestsDefaultValues) as InterestKey[]
                      ).map((interestKey) => (
                        <SelectionChip
                          {...rest}
                          key={interestKey}
                          onChange={(e) =>
                            onChange({
                              ...value,
                              [interestKey]: e.target.checked,
                            })
                          }
                          checked={value[interestKey]}
                          name={`${name}.${interestKey}`}
                          icon={interestsIcons[interestKey]}
                          label={LL.onboarding.interests[interestKey]()}
                        />
                      ))}
                    </div>
                  );
                }}
              />
              <AriaButton
                onPress={nextStep}
                className="mt-4 w-full"
                isDisabled={
                  !firstStepFields.every((field) => isFieldValid(field))
                }
                loading={isSubmitting || onboard.isLoading}
              >
                {LL.continue()}
              </AriaButton>
            </div>
          </motion.div>
        )}

        {/* Step 2 */}
        {step === 2 && (
          <motion.div {...stepAnimationProps} className="space-y-6">
            <h1 className="text-pretty text-2xl font-semibold leading-8">
              {LL.onboarding.step2Title()}
            </h1>
            <Controller
              name="userIndustry"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => {
                function handleIndustryChange(newValue: UserIndustry) {
                  const isSelectedIndustryInDisplay =
                    displayIndustries.includes(newValue);

                  if (!isSelectedIndustryInDisplay) {
                    setDisplayIndustries([
                      newValue,
                      ...displayIndustries.slice(0, -1),
                    ]);
                  }
                  onChange(newValue);
                }

                return (
                  <div className="space-y-4">
                    <Select<UserIndustry>
                      error={errors.userIndustry?.message}
                      value={value}
                      renderOptionLabel={(opt) => LL.industries[opt]?.() ?? opt}
                      options={userIndustries}
                      onChange={handleIndustryChange}
                      allowSearch
                      {...rest}
                    />
                    <div className="grid grid-cols-3 gap-4 p-px">
                      <AnimatePresence mode="popLayout">
                        {displayIndustries.map((industry) => (
                          <motion.div
                            key={industry}
                            layout
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            transition={{ duration: 0.15 }}
                          >
                            <SelectionChip
                              checked={value === industry}
                              onClick={() => handleIndustryChange(industry)}
                              icon={userIndustriesIcons[industry]}
                              label={LL.industries[industry]()}
                              className="h-full"
                            />
                          </motion.div>
                        ))}
                      </AnimatePresence>
                    </div>
                  </div>
                );
              }}
            />
            <div className="flex gap-2">
              <AriaButton
                variant="white"
                onPress={() => {
                  direction.current = -1;
                  setStep(step - 1);
                }}
                className="flex-1"
              >
                {LL.back()}
              </AriaButton>
              <AriaButton
                htmlType="submit"
                isDisabled={!isValid}
                loading={isSubmitting || onboard.isLoading}
                className="flex-1"
              >
                {LL.onboarding.getStarted()}
              </AriaButton>
            </div>
          </motion.div>
        )}
      </form>
    </BaseModal>
  );
};

export default OnboardingModal;
