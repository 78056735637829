import React, {
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  getAllMetricsAndAttributesForPlatform,
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS,
  InsightsFacebookActionMetricName,
  InsightsFacebookNonActionMetricName,
  InsightsFacebookTimeSeriesMetricName,
  InsightsFacebookWizardMetric,
} from '@magicbrief/common';
import clsx from 'clsx';
import {
  Button,
  Checkbox,
  Dialog,
  DialogTrigger,
  Input,
  ListBox,
  ListBoxItem,
  Modal,
  ModalOverlay,
  SearchField,
  Text,
} from 'react-aria-components';
import { useParams } from 'react-router-dom';
import Eye from 'src/assets/svgicons/duotone/eye.svg';
import SearchSm from 'src/assets/svgicons/duotone/search-sm.svg';
import Check from 'src/assets/svgicons/line/check.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import { AriaButton } from 'src/components/Button/Button';
import { MagicBriefButton } from 'src/components/Button/MagicBriefButton';
import { Icon } from 'src/components/Icon';
import { UpgradeModalV2 } from 'src/components/Modals/UpgradeModalV2/UpgradeModalV2';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { useEntitlementQuotas } from 'src/utils/useEntitlementQuotas';
import {
  useInsightsDisplay,
  useInsightsPlatform,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import { useInsightsAdAccount } from '../../util/useInsightsQueries';
import { useMetricSearch } from '../../util/useMetricSearch';
import { useParseMetric } from '../../util/useParseMetric';
import { InsightsMetricLabelTooltip } from '../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';

export const InsightsFilterDisplayMenuV2: React.FC<{
  platform: 'facebook' | 'tiktok';
}> = ({ platform }) => {
  const { LL } = useI18nContext();
  const { hasEntitlement } = useEntitlementQuotas('insights_ad_account');

  const [isOpen, setIsOpen] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  return (
    <>
      <DialogTrigger>
        <AriaButton
          data-intercom-target="insight_customMetrics"
          variant="white"
          icon={
            <Icon>
              <Eye />
            </Icon>
          }
          onPress={() => {
            if (hasEntitlement) {
              setIsOpen(true);
            } else {
              setShowUpgradeModal(true);
            }
          }}
        >
          {LL.insights.card.tabs.metrics()}
        </AriaButton>
        <InsightsDisplayMetricModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          platform={platform}
        />
      </DialogTrigger>
      <UpgradeModalV2
        isOpen={showUpgradeModal}
        close={() => setShowUpgradeModal(false)}
        prompt="to unlock insights"
      />
    </>
  );
};

interface InsightsDisplayMetricModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  platform: 'facebook' | 'tiktok';
}

export function InsightsDisplayMetricModal({
  isOpen,
  setIsOpen,
  platform,
}: InsightsDisplayMetricModalProps) {
  const { accountUuid } = useParams();
  const {
    accountCustomEvents: customEvents,
    accountCustomConversions: customConversions,
  } = useInsightsAdAccount({ accountUuid });

  const searchRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] w-[970px] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog
          className="relative grid h-[800px] rounded-2xl border border-purple-200 bg-white shadow focus-visible:outline-none lg:h-auto"
          aria-label="billing plans modal"
        >
          {({ close }) => (
            <>
              <DisplayFilterModalHeader />
              <div className="flex h-[500px]">
                <SelectedDisplayFilters
                  customEvents={customEvents ?? []}
                  customConversions={customConversions ?? []}
                />
                <DisplayFiltersSelection
                  platform={platform}
                  customEvents={customEvents ?? []}
                  customConversions={customConversions ?? []}
                />
              </div>
              <DisplayFilterModalFooter close={close} />
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}

function DisplayFilterModalHeader() {
  return (
    <div className="border-b border-solid border-b-purple-300 px-6 py-4">
      <h2 className="text-xl font-semibold text-primary">
        Customise Visible Metrics
      </h2>
    </div>
  );
}

function DisplayFilterModalFooter({ close }: { close: () => void }) {
  return (
    <div className="flex items-center justify-end gap-2 rounded-b-[inherit] border-t border-solid border-t-purple-300 bg-white px-6 py-4">
      <MagicBriefButton
        className="w-fit"
        variant="text"
        size="small"
        onPress={close}
      >
        Cancel
      </MagicBriefButton>
      <MagicBriefButton
        className="w-fit"
        variant="primary"
        size="small"
        onPress={close}
      >
        Update Metrics
      </MagicBriefButton>
    </div>
  );
}

const SelectedDisplayFilters: React.FC<CustomEventsAndConversionsProps> = ({
  customEvents,
  customConversions,
}) => {
  const dispatch = useInsightsStoreDispatch();
  const display = useInsightsDisplay();

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = display.indexOf(active.id as string);
      const newIndex = display.indexOf(over.id as string);
      const newDisplayOrder = arrayMove(display, oldIndex, newIndex);

      dispatch({ type: 'setDisplay', value: newDisplayOrder });
    }
  };

  if (display.length === 0) {
    return (
      <div className="flex w-[300px] min-w-[300px] flex-col gap-4 border-r border-solid border-r-purple-200 px-6 pt-3">
        <span className="text-lg font-semibold text-primary">Selected</span>
        <span className="text-md font-medium text-primary opacity-50">
          No metrics selected.
        </span>
      </div>
    );
  }

  return (
    <div className="flex h-[500px] w-[300px] min-w-[300px] flex-col gap-4 overflow-auto border-r border-solid border-r-purple-200 px-6 pt-3">
      <span className="text-lg font-semibold text-primary">Selected</span>

      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext items={display} strategy={verticalListSortingStrategy}>
          <ListBox className="space-y-2" aria-label="display filters">
            {display.map((id) => (
              <DraggableDisplayFilter
                key={id}
                id={id}
                customEvents={customEvents}
                customConversions={customConversions}
              />
            ))}
          </ListBox>
        </SortableContext>
      </DndContext>
    </div>
  );
};

interface CustomEventsAndConversionsProps {
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
}

interface DraggableDisplayFilterProps extends CustomEventsAndConversionsProps {
  id: string;
}

const DraggableDisplayFilter: React.FC<DraggableDisplayFilterProps> = ({
  id,
  customEvents,
  customConversions,
}) => {
  const platform = useInsightsPlatform();
  const dispatch = useInsightsStoreDispatch();
  const { getMetricLabelAsText } = useParseMetric();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const styles: React.CSSProperties = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition,
  };

  return (
    <ListBoxItem
      ref={setNodeRef}
      className="flex items-center gap-1.5 rounded-md border border-solid border-purple-200 bg-violet-50 px-2.5 py-1.5"
      style={styles}
      aria-label="selected display metric"
    >
      <button
        {...attributes}
        {...listeners}
        className="cursor-grab text-purple-300 active:cursor-grabbing focus:outline-none"
      >
        <Icon className="size-4">
          <DotsGrid />
        </Icon>
      </button>
      <Text
        slot="label"
        className="grow truncate text-sm font-semibold text-primary"
      >
        {getMetricLabelAsText(platform, id, customEvents, customConversions)}
      </Text>

      <Button
        onPress={() => dispatch({ type: 'toggleDisplay', value: id })}
        className="focus:outline-none"
      >
        <Icon className="size-[18px] text-primary opacity-50">
          <XClose />
        </Icon>
      </Button>
    </ListBoxItem>
  );
};

type Category = {
  type: 'list' | 'grid';
  metrics: Array<string>;
};

const DisplayFiltersSelection: React.FC<
  CustomEventsAndConversionsProps & { platform: 'facebook' | 'tiktok' }
> = ({ customEvents, customConversions, platform }) => {
  const { LL } = useI18nContext();
  const searchRef = useRef<HTMLInputElement>(null);

  const {
    results: items,
    onChange,
    query,
  } = useMetricSearch({
    platform,
    builtinMetrics: getAllMetricsAndAttributesForPlatform(platform),
    customEvents,
    customConversions,
  });

  const mostRelevantSearchMetrics = items.slice(0, 10);
  const categorisedMetrics = (
    items as Array<InsightsFacebookTimeSeriesMetricName>
  ).reduce<Record<string, Category>>((acc, curr) => {
    switch (true) {
      case DEPRECATED_METRICS.some((x) => x === curr):
        return acc;

      case customEvents.some((ce) => curr.includes(ce)): {
        if (acc['Custom Events']) {
          acc['Custom Events']['metrics'].push(curr);
        } else {
          acc['Custom Events'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;
      }

      case customConversions.some((cc) => curr.includes(cc.facebookId)):
        if (acc['Custom Conversions']) {
          acc['Custom Conversions']['metrics'].push(curr);
        } else {
          acc['Custom Conversions'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;

      case STANDARD_CONVERSIONS_CATEGORY_METRICS.some((x) => curr.includes(x)):
        if (acc['Standard Conversions']) {
          acc['Standard Conversions']['metrics'].push(curr);
        } else {
          acc['Standard Conversions'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;

      case CLICKS_CATEGORY_METRICS.includes(curr):
        if (acc['Clicks']) {
          acc['Clicks']['metrics'].push(curr);
        } else {
          acc['Clicks'] = { type: 'list', metrics: [curr] };
        }
        break;

      case VIDEO_CATEGORY_METRICS.includes(curr):
        if (acc['Video']) {
          acc['Video']['metrics'].push(curr);
        } else {
          acc['Video'] = { type: 'list', metrics: [curr] };
        }
        break;

      case ENGAGEMENT_CATEGORY_METRICS.includes(curr):
        if (acc['Engagement']) {
          acc['Engagement']['metrics'].push(curr);
        } else {
          acc['Engagement'] = { type: 'list', metrics: [curr] };
        }
        break;

      case WIZARD_SCORE_CATEGORY_METRICS.includes(
        curr as InsightsFacebookWizardMetric
      ):
        if (acc['Wizard Scores']) {
          acc['Wizard Scores']['metrics'].push(curr);
        } else {
          acc['Wizard Scores'] = { type: 'list', metrics: [curr] };
        }
        break;

      case AD_DETAILS_CATEGORY_METRICS.includes(curr):
        if (acc['Ad Details']) {
          acc['Ad Details']['metrics'].push(curr);
        } else {
          acc['Ad Details'] = { type: 'list', metrics: [curr] };
        }
        break;

      case PERFORMANCE_CATEGORY_METRICS.includes(curr):
        if (acc['Performance']) {
          acc['Performance']['metrics'].push(curr);
        } else {
          acc['Performance'] = { type: 'list', metrics: [curr] };
        }
        break;

      default:
        if (acc['Other']) {
          acc['Other']['metrics'].push(curr);
        } else {
          acc['Other'] = {
            type: 'list',
            metrics: [curr],
          };
        }
    }

    return acc;
  }, {});

  useLayoutEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <div className="size-full">
      <div className="flex flex-col gap-4 border-b border-solid border-b-purple-200 px-6 pb-6 pt-3">
        <span className="text-lg font-semibold text-primary">
          Search Metrics
        </span>
        <SearchField className="relative" aria-label="display metric search">
          <Icon className="absolute left-3 top-1/2 size-5 -translate-y-1/2 text-primary">
            <SearchSm />
          </Icon>
          <Input
            ref={searchRef}
            type="text"
            className="text-md w-full rounded-md border border-solid border-purple-200 bg-white py-2.5 pl-10 pr-3 font-medium text-primary shadow-sm focus:outline-none"
            onChange={(e) => onChange(e.target.value)}
            placeholder={LL.insights.metrics.search()}
          />
        </SearchField>
      </div>

      <div className="flex h-[373px] flex-col gap-10 overflow-auto px-6 pt-6">
        {items.length === 0 && (
          <p className="primary text-sm italic text-purple-400">
            {`${LL.insights.metrics.noResults()} for '${query}'`}
          </p>
        )}

        {mostRelevantSearchMetrics.length > 0 && query.length > 0 && (
          <ListCategoryGroup
            customEvents={customEvents}
            customConversions={customConversions}
            name="Most Relevant"
            metrics={mostRelevantSearchMetrics.filter((m) => m !== 'tag') ?? []}
            withCount={false}
          />
        )}

        {platform === 'tiktok' && (
          <ListCategoryGroup
            customEvents={customEvents}
            customConversions={customConversions}
            name="All Metrics"
            metrics={getAllMetricsAndAttributesForPlatform(platform)}
          />
        )}

        {platform === 'facebook' && (
          <>
            {categorisedMetrics['Performance'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Performance"
                metrics={categorisedMetrics['Performance'].metrics ?? []}
              />
            )}
            {categorisedMetrics['Wizard Scores'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Wizard Scores"
                metrics={categorisedMetrics['Wizard Scores'].metrics ?? []}
              />
            )}
            {categorisedMetrics['Clicks'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Clicks"
                metrics={categorisedMetrics['Clicks'].metrics ?? []}
              />
            )}

            {categorisedMetrics['Video'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Video"
                metrics={categorisedMetrics['Video'].metrics ?? []}
              />
            )}
            {categorisedMetrics['Engagement'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Engagement"
                metrics={categorisedMetrics['Engagement'].metrics ?? []}
              />
            )}
            {categorisedMetrics['Ad Details'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Ad Details"
                metrics={categorisedMetrics['Ad Details'].metrics ?? []}
              />
            )}
            {categorisedMetrics['Standard Conversions'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Standard Conversions"
                metrics={
                  categorisedMetrics['Standard Conversions'].metrics ?? []
                }
              />
            )}
            {categorisedMetrics['Custom Conversions'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Custom Conversions"
                metrics={categorisedMetrics['Custom Conversions'].metrics ?? []}
              />
            )}
            {categorisedMetrics['Custom Events'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Custom Events"
                metrics={categorisedMetrics['Custom Events'].metrics ?? []}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

// Override the alphabetical sort for Wizard scores and orders
// it in the correct sequence as per the conversion funnel
const wizardScoreSortMap = {
  wizardHookScore: 1,
  wizardHoldScore: 2,
  wizardClickScore: 3,
  wizardBuyScore: 4,
};

interface ListCategoryGroupProps extends CustomEventsAndConversionsProps {
  name: string;
  metrics: Array<string>;
  withCount?: boolean;
}

const ListCategoryGroup: React.FC<ListCategoryGroupProps> = ({
  name,
  metrics,
  withCount = true,
  customEvents,
  customConversions,
}) => {
  const dispatch = useInsightsStoreDispatch();
  const display = useInsightsDisplay();
  const platform = useInsightsPlatform();

  const header = withCount ? `${name} (${metrics.length})` : name;

  const parsedMetrics =
    name === 'Wizard Scores'
      ? metrics.sort(
          (a, b) =>
            wizardScoreSortMap[a as InsightsFacebookWizardMetric] -
            wizardScoreSortMap[b as InsightsFacebookWizardMetric]
        )
      : metrics;

  return (
    <div className="space-y-2.5">
      <span className="text-md font-semibold text-primary">{header}</span>
      <div className="space-y-0.5">
        {parsedMetrics.map((metric) => {
          return (
            <Checkbox
              key={metric}
              isSelected={display.includes(metric)}
              onChange={() =>
                dispatch({ type: 'toggleDisplay', value: metric })
              }
              className="group/checkbox flex cursor-pointer items-center gap-2.5 px-3 py-2.5 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <div
                className={clsx(
                  /** Base */
                  'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
                  /** Selected */
                  'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100'
                )}
              >
                <Icon className="hidden size-4 text-white group-selected/checkbox:block">
                  <Check />
                </Icon>
              </div>
              <span className="truncate text-sm font-medium text-primary">
                <InsightsMetricLabelTooltip
                  platform={platform}
                  metric={metric}
                  customConversions={customConversions}
                  customEvents={customEvents}
                />
              </span>
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
};

interface GridCategoryGroupProps extends CustomEventsAndConversionsProps {
  name: string;
  metrics: Array<string>;
}

const GridCategoryGroup: React.FC<GridCategoryGroupProps> = ({
  name,
  metrics,
  customEvents,
  customConversions,
}) => {
  const dispatch = useInsightsStoreDispatch();
  const display = useInsightsDisplay();
  const platform = useInsightsPlatform();

  const groupMetrics = metrics.reduce(
    (acc: Record<string, Array<string>>, curr) => {
      const splitIndex = getNthOccurrence(curr, ':', 2);
      const metricCategory = curr.slice(splitIndex + 1);

      if (acc[metricCategory]) {
        acc[metricCategory].push(curr);
      } else {
        acc[metricCategory] = [curr];
      }

      return acc;
    },
    {}
  );

  const customTrigger = ({ children }: PropsWithChildren) => (
    <Button className="text-xs font-medium text-primary">{children}</Button>
  );

  return (
    <div className="space-y-2.5">
      <span className="text-md font-semibold text-primary">{name}</span>

      <div className="grid grid-cols-[250px_repeat(3,1fr)]">
        <div />
        {['Total', 'Value', 'Cost'].map((header) => (
          <span
            key={header}
            className="p-2 text-center text-xxs font-semibold text-primary"
          >
            {header}
          </span>
        ))}
      </div>

      {Object.entries(groupMetrics).map(([key]) => {
        return (
          <div
            key={key}
            className="grid grid-cols-[250px_repeat(3,1fr)] items-center justify-center"
          >
            <InsightsMetricLabelTooltip
              metric={`action:total:${key}`}
              customConversions={customConversions}
              customEvents={customEvents}
              customTrigger={customTrigger}
              platform={platform}
            />
            {['total', 'value', 'cost_per'].map((item) => {
              const metric = `action:${item}:${key}`;
              const isDisabled = !metrics.includes(metric);

              return (
                <Checkbox
                  key={item}
                  isSelected={display.includes(metric)}
                  onChange={() =>
                    dispatch({ type: 'toggleDisplay', value: metric })
                  }
                  className="group/checkbox mx-auto flex cursor-pointer items-center gap-2.5 px-3 py-2.5 disabled:cursor-not-allowed disabled:opacity-50"
                  isDisabled={isDisabled}
                >
                  <div
                    className={clsx(
                      /** Base */
                      'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
                      /** Selected */
                      'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100',
                      /** Disabled */
                      'group-disabled/checkbox:bg-purple-100'
                    )}
                  >
                    <Icon className="hidden size-4 text-white group-selected/checkbox:block">
                      <Check />
                    </Icon>
                  </div>
                </Checkbox>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const DEPRECATED_METRICS = ['thumbstop'];

const CLICKS_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> = [
  // Clicks (All)
  'clicks',
  // CPC (All)
  'cpc',
  // CTR (All)
  'ctr',
  // Clicks (Links)
  'action:total:link_click',
  // Clicks (Unique, Links)
  'action:unique:link_click',
  // Cost Per Click (Unique, Links)
  'action:cost_per_unique:link_click',
  // CPC (Links)
  // 'costPerLinkClick', // Deprecated
  // CTR (Links)
  'clickThroughRateAll',
  // Clicks (Outbound)
  'outboundClicks',
  // CPC (Outbound)
  'costPerOutboundClick',
  // Clicks (Unique, Outbound)
  'action:unique:outbound_click',
  // Cost Per Click (Unique, Outbound)
  // 'action:cost_per_unique:outbound_click', // Duplicates column level costPerUniqueOutboundClick
  // CTR (Outbound)
  'outboundClicksCtr',
  // Clicks (Unique, All)
  'uniqueClicks',
  // CPC (Unique, All)
  'costPerUniqueClick',
  // CTR (Unique, All)
  'clickThroughRateUnique',
  // CPC (Unique, Links)
  'costPerUniqueLinkClick',
  // CTR (Unique, Links)
  'clickThroughRateUniqueLinks',
  // CPC (Unique, Outbound)
  'costPerUniqueOutboundClick',
  // CTR (Unique, Outbound)
  'clickThroughRateUniqueOutbound',
  // Click to Purchase Ratio
  'clickToPurchaseRatio',
  // Click to Purchase Ratio (Unique)
  'clickToPurchaseRatioUnique',
  // Click to Registration Completed Ratio
  'clickToRegistrationCompletedRatio',
  // Click to Website Purchase Ratio
  // Click to Add-To-Cart Ratio
  'clickToAddToCartRatio',
  // Cost Per Link Clicks
  'action:cost_per:link_click',
  // Cost Per Inline Link Click
  'costPerInlineLinkClick',
  // Link Clicks Value
  'action:value:link_click',
  // Inline Link Clicks
  'inlineLinkClicks',
  // Inline Link Clicks CTR
  'inlineLinkClickCtr',
];

const AD_DETAILS_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Ad Name
    'adName',
    // Ad ID
    // Ad Set Name
    'adSetName',
    // Ad Set ID
    // Campaign Name
    'campaignName',
    // Campaign ID
    // Created At
    'adCreatedAt',
    // Updated At
    'adUpdatedAt',
    // Start At
    'adStartAt',
    // End At
    'adEndAt',
    // Creative Type
    'creativeType',
  ];

const VIDEO_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> = [
  //   Video Plays (All)
  'videoPlays',
  // Video Average Play Time
  'videoAvgTimeWatched',
  //   Video Plays (2 Second Plays)
  'video2SecondContinuousPlays',
  // Cost Per 2 Second Video Play
  'costPerVideo2SecondContinuousPlay',
  // Cost Per Unique 2 Second Video Play
  // Video Plays (3 Second Plays)
  'action:total:video_view',
  // Cost Per 3 Second Video Play
  'action:cost_per:video_view',
  // Video 25% Watched Views
  'video25PercentWatchedViews',
  // Video 25% Watched Rate
  // Video 50% Watched Views
  'video50PercentWatchedViews',
  // Video 50% Watched Rate
  // Video 75% Watched Views
  'video75PercentWatchedViews',
  // Video 75% Watched Rate
  // Video 95% Watched Views
  'video95PercentWatchedViews',
  // Video 95% Watched Rate
  // Video 100% Watched Views
  'video100PercentWatchedViews',
  // Video 100% Watched Rate
  // Thruplays
  'thruplay',
  // Cost Per Thruplay
  'costPerThruplay',
  // CTR (Thruplay)
  'thruplayClickThroughRate',
  // 15 Sec/3 Sec Video Retention
  'video15Sec3SecVideoRetention',
  // Thruplay Rate
  'holdplay',
  // Thumbstop Rate
  'thumbstopRatio',
  // First Frame Retention
  'firstFrameRetention',
  // Cost Per Video Thruplay
  'costPerVideoThruplay',
  // Video Plays
  'videoPlays',
  // Video 30 Sec Watched Views
  'video30SecWatchedViews',
  // Video Avg Time Watched
  'videoAvgTimeWatched',
  // Thumbstop
  'thumbstop',
];

const ENGAGEMENT_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Post Engagements
    'action:total:post_engagement',
    // Post Reactions
    'action:total:post_reaction',
    // Post Saves
    'action:total:onsite_conversion.post_save',
    // Post Shares
    'action:total:post',
    // Post Comments
    'action:total:comment',
    // Cost Per Post Engagement
    'action:cost_per:post_engagement',
    // Page Follows or Likes
    'action:total:like',
    // Cost Per Page Follow or Like
    'action:cost_per:like',
    // Page Engagements
    'action:total:page_engagement',
    // Cost Per Page Engagement
    'action:cost_per:page_engagement',
    // Cost Per Post Shares
    'action:cost_per:post',
    // Cost Per Post Saves
    'action:cost_per:onsite_conversion.post_save',
    // Cost Per Post Reactions
    'action:cost_per:post_reaction',
    // Cost Per Post Comments
    'action:cost_per:comment',
    // Cost Per Pixel
    'cpp',
    // Cost Per Inline Post Engagement
    'costPerInlinePostEngagement',
    // Engagement
    'engagement',
    // Engagement Rate
    'engagementRate',
    // Page Engagement Value
    'action:value:page_engagement',
    // Post Comments Value
    'action:value:comment',
    // Post Engagement Value
    'action:value:post_engagement',
    // Post Reactions Value
    'action:value:post_reaction',
    // Post Saves Value
    'action:value:onsite_conversion.post_save',
    // Post Shares Value
    'action:value:post',
    // See More Rate
    'seeMoreRate',
    // Canvas Avg View Time
    'canvasAvgViewTime',
    // Canvas Avg View %
    'canvasAvgViewPercent',
    // Interest
    'interest',
    // Cost Per Result
    'cpr',
  ];

const PERFORMANCE_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Reach
    'reach',
    // Reach (Full View)
    'fullViewReach',
    // Frequency
    'frequency',
    // Impressions
    'impressions',
    // Impressions (Full View)
    'fullViewImpressions',
    // CPM
    'cpm',
    // PPM
    'purchasesPerMille',
    // RPM
    'revenuePerMille',
    // IPM
    'installsPerMille',
    // Delivery
    'effectiveStatus',
    // Ad Set Delivery
    // Spend
    'spend',
    // Spend %
    'spendPercentage',
    // ROAS
    'roas',
    // ROAS (Website Purchases)
    // AOV
    'avgOrderValue',
    // Purchase Value %
    'purchaseValuePercentage',
    // Ad Recall Lift (People)
    // Cost Per Ad Recall Lift (People)
    // Conversions
    'conversions',
    // Add-to-Cart To Purchase Ratio
    'addToCartToPurchaseRatio',
  ];

const WIZARD_SCORE_CATEGORY_METRICS =
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS;

const STANDARD_CONVERSIONS_CATEGORY_METRICS: Array<
  InsightsFacebookNonActionMetricName | InsightsFacebookActionMetricName
> = [
  // 20s Calls Placed (Select countries)
  'click_to_call_native_20s_call_connect',
  // 3-Second Video Views
  'video_view',
  // 60s Calls Placed (Select countries)
  'click_to_call_native_60s_call_connect',
  // Achivements (Mobile)
  'app_custom_event.fb_mobile_level_achieved',
  // Achievements Unlocked (All)
  'omni_achievement_unlocked',
  // Adds of Payment Info (All? Undocumented action)
  'add_payment_info',
  // Adds of Payment Info (Total)
  // Adds of Payment Info (Mobile)
  'app_custom_event.fb_mobile_add_payment_info',
  // Adds of Payment Info (Website)
  'offsite_conversion.fb_pixel_add_payment_info',
  // Add-To-Carts
  'omni_add_to_cart',
  // Add-To-Carts (Pixel)
  'offsite_conversion.fb_pixel_add_to_cart',
  // Add-To-Carts (Mobile)
  'app_custom_event.fb_mobile_add_to_cart',
  // Add-To-Wishlists
  // Adds-To-Wishlist (Mobile)
  'app_custom_event.fb_mobile_add_to_wishlist',
  // Adds-To-Wishlist (Website)
  'offsite_conversion.fb_pixel_add_to_wishlist',
  // App Activations
  'omni_activate_app',
  // App Installs (All)
  'omni_app_install',
  // App Installs (Website)
  'app_install',
  // App Installs (Mobile)
  'mobile_app_install',
  // App Opened (Mobile)
  'app_custom_event.fb_mobile_activate_app',
  // App Registrations (Mobile)
  'app_custom_event.fb_mobile_complete_registration',
  // App Uses (Website)
  'app_use',
  // Applications Submitted
  'submit_application_total',
  // Applications Submitted (Website)
  'submit_application_website',
  // Applications Submitted (Mobile)
  'submit_application_mobile_app',
  // Applications Submitted (Offline)
  'submit_application_offline',
  // Applications Submitted (On Facebook)
  'submit_application_on_facebook',
  // Appointments Scheduled (Total)
  'schedule_total',
  // Appointments Scheduled (Website)
  'schedule_website',
  // Appointments Scheduled (Mobile)
  'schedule_mobile_app',
  // Appointments Scheduled (Offline)
  'schedule_offline',
  // Blocked Messaging Conversations
  'onsite_conversion.messaging_block',
  // Calls Placed (Select Countries)
  'click_to_call_native_call_placed',
  // Cancelled Subscriptions (Total)
  'cancel_subscription_total',
  // Cancelled Subscriptions (Website)
  'cancel_subscription_website',
  // Cancelled Subscriptions (Mobile)
  'cancel_subscription_mobile_app',
  // Cancelled Subscriptions (Offline)
  'cancel_subscription_offline',
  // Check-ins
  'checkin',
  // Checkouts (Mobile)
  'app_custom_event.fb_mobile_initiated_checkout',
  // Checkouts Intiated
  'omni_initiated_checkout',
  // Completed Registrations
  'complete_registration',
  // Contacts (Total)
  'contact_total',
  // Contacts (Website)
  'contact_website',
  // Contacts (Mobile)
  'contact_mobile_app',
  // Contacts (Offline)
  'contact_offline',
  // Content Views (All)
  'omni_view_content',
  // Content Views (Mobile)
  'app_custom_event.fb_mobile_content_view',
  // Credit Spends
  'credit_spent',
  // Credit Spends (All)
  'omni_spend_credits',
  // Credit Spends (Mobile)
  'app_custom_event.fb_mobile_spent_credits',
  // Custom Events (All)
  'omni_custom',
  // Custom pixel events defined by the advertiser
  'offsite_conversion.fb_pixel_custom',
  // Donations (Total)
  'donate_total',
  // Donations (Website)
  'donate_website',
  // Donations (Mobile)
  'donate_mobile_app',
  // Donations (On Facebook)
  'donate_on_facebook',
  // Donations (Offline)
  'donate_offline',
  // Estimated Call Confirmation Clicks
  'click_to_call_call_confirm',
  // Event Responses
  'rsvp',
  // Feature Unlocks (Mobile)
  'app_custom_event.fb_mobile_achievement_unlocked',
  // Game Plays
  'games.plays',
  // In-App Ad Clicks
  'ad_click_mobile_app',
  // In-App Ad Impressions
  'ad_impression_mobile_app',
  // Initiates Checkout (Pixel)
  'offsite_conversion.fb_pixel_initiate_checkout',
  // Landing Page Views
  'landing_page_view',
  // Leads (Offsite)
  'offsite_conversion.fb_pixel_lead',
  // Leads (Offsite + On-Facebook)
  'lead',
  // Leads (On-Facebook from Messenger + Instant Forms)
  'leadgen_grouped',
  // Leads (On-Facebook)
  'onsite_conversion.lead_grouped',
  // Levels Achieved
  'omni_level_achieved',
  // Locations Searches (Total)
  'find_location_total',
  // Locations Searches (Website)
  'find_location_website',
  // Locations Searches (Mobile)
  'find_location_mobile_app',
  // Locations Searches (Offline)
  'find_location_offline',
  // Messaging Conversations Started
  'onsite_conversion.messaging_conversation_started_7d',
  // Messaging Subscriptions
  'onsite_conversion.messaging_user_subscribed',
  // Mobile App Tutorial Completions (Mobile)
  'app_custom_event.fb_mobile_tutorial_completion',
  // New Messaging Conversations
  'onsite_conversion.messaging_first_reply',
  // Offline Conversions
  // On-Facebook Web Leads
  'onsite_web_lead',
  // On-Facebook Workflow Completions
  'onsite_conversion.flow_complete',
  // Other Mobile App Actions
  'app_custom_event.other',
  // Page Likes
  'like',
  // Page Photo Views
  'photo_view',
  // Products Customised (Total)
  'customize_product_total',
  // Products Customised (Website)
  'customize_product_website',
  // Products Customised (Mobile)
  'customize_product_mobile_app',
  // Products Customised (Offline)
  'customize_product_offline',
  // Purchases (Total)
  'omni_purchase',
  // Purchases (Website)
  // Purchases (Offline)
  // Purchases (Mobile)
  'app_custom_event.fb_mobile_purchase',
  // Purchases (Pixel)
  'offsite_conversion.fb_pixel_purchase',
  // Purchases (On-Facebook)
  'onsite_conversion.purchase',
  // Ratings (Mobile)
  'app_custom_event.fb_mobile_rate',
  // Ratings Submitted
  'omni_rate',
  // Recurring Subscription Payments (Total)
  'recurring_subscription_payment_total',
  // Recurring Subscription Payments (Website)
  'recurring_subscription_payment_website',
  // Recurring Subscription Payments (Mobile)
  'recurring_subscription_payment_mobile_app',
  // Recurring Subscription Payments (Offline)
  'recurring_subscription_payment_offline',
  // Registations Completed
  'omni_complete_registration',
  // Searches (All)
  'omni_search',
  // Searches (Mobile)
  'app_custom_event.fb_mobile_search',
  // Searches (Pixel)
  'offsite_conversion.fb_pixel_search',
  // Subscriptions (Total)
  'subscribe_total',
  // Subscriptions (Website)
  'subscribe_website',
  // Subscriptions (Mobile)
  'subscribe_mobile_app',
  // Subscriptions (Offline)
  'subscribe_offline',
  // Total Messaging Connections
  'onsite_conversion.total_messaging_connection',
  // Trials Started (Total)
  'start_trial_total',
  // Trials Started (Website)
  'start_trial_website',
  // Trials Started (Mobile)
  'start_trial_mobile_app',
  // Trials Started (Offline)
  'start_trial_offline',
  // Tutorials Completed
  'omni_tutorial_completion',
  // Views Content (Pixel)
  'offsite_conversion.fb_pixel_view_content',
];

/**
 * Finds the index of the n-th occurence of a substring wtihin a string
 */
function getNthOccurrence(str: string, substring: string, n: number) {
  let count = 0;
  let position = 0;

  while (count < n) {
    position = str.indexOf(substring, position);

    if (position === -1) {
      return -1;
    }

    count++;
    position++;
  }

  return position - 1;
}
