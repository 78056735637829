import { getQueryKey } from '@trpc/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { auth } from 'src/lib/firebase';
import queryClient from 'src/lib/queryClient';
import { trpc, trpcProxyClient } from 'src/lib/trpc';
import { GetAdAccountResponse } from 'src/types/insights';

export async function insightsDashboardRouteLoader({
  params,
}: LoaderFunctionArgs) {
  const { accountUuid = '' } = params;

  await auth.authStateReady();

  const account = await queryClient.ensureQueryData<GetAdAccountResponse>(
    getQueryKey(
      trpc.insightsAccounts.getAdAccount,
      { uuid: accountUuid },
      'query'
    ),
    async () => {
      const data = await trpcProxyClient.insightsAccounts.getAdAccount.query({
        uuid: accountUuid,
      });

      return data;
    }
  );

  void queryClient.prefetchQuery({
    queryKey: getQueryKey(
      trpc.insightsDashboard.getPerformanceForInsightsAdAccount,
      { adAccountUuid: accountUuid },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insightsDashboard.getPerformanceForInsightsAdAccount.query(
        {
          adAccountUuid: accountUuid,
        },
        {
          context: { skipBatch: true },
        }
      ),
  });

  void queryClient.prefetchQuery({
    queryKey: getQueryKey(
      trpc.insightsDashboard.getWinnersForInsightsAdAccount,
      { adAccountUuid: accountUuid },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insightsDashboard.getWinnersForInsightsAdAccount.query(
        {
          adAccountUuid: accountUuid,
        },
        {
          context: { skipBatch: true },
        }
      ),
  });

  void queryClient.prefetchQuery({
    queryKey: getQueryKey(
      trpc.insightsDashboard.getRecommendationsForInsightsAdAccount,
      { adAccountUuid: accountUuid },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insightsDashboard.getRecommendationsForInsightsAdAccount.query(
        {
          adAccountUuid: accountUuid,
        },
        {
          context: { skipBatch: true },
        }
      ),
  });

  return account;
}
