import { InsightsFilter, InsightsMetricOperationV2 } from '@magicbrief/common';
import {
  Button,
  ListBox,
  ListBoxItem,
  Select,
  SelectValue,
} from 'react-aria-components';
import { useFormContext } from 'react-hook-form';
import { Icon } from 'src/components/Icon';
import { cn } from 'src/lib/cn';
import { getOperationsAndLabelsForField } from 'src/pages/Insights/util/insightsFilterOperations';
import ChevronDown from 'src/assets/svgicons/duocolor/chevron-down.svg';
import { Popover } from 'src/components/Popover/AriaPopover';

type InsightsFormFilterMetricOperationSelectProps = {
  prefix: string;
  platform: 'facebook' | 'tiktok';
  onChange: (operation: string) => void;
  className?: string;
  error?: string | undefined;
};

export const InsightsFormFilterMetricOperationSelect: React.FC<
  InsightsFormFilterMetricOperationSelectProps
> = ({ prefix, onChange, className, error, platform }) => {
  const { watch } = useFormContext();
  const rootValue: InsightsFilter = watch(prefix);

  return (
    <InsightsFilterMetricOperationSelect
      platform={platform}
      field={rootValue.field}
      value={rootValue.operation}
      onChange={onChange}
      className={className}
      error={error}
    />
  );
};

type InsightsFilterMetricOperationSelectProps = {
  field: string;
  platform: 'facebook' | 'tiktok';
  value?: InsightsMetricOperationV2;
  onChange: (operation: string) => void;
  className?: string;
  error?: string | undefined;
};

export const InsightsFilterMetricOperationSelect: React.FC<
  InsightsFilterMetricOperationSelectProps
> = ({ field, onChange, className, value, platform }) => {
  const { options, labels } = getOperationsAndLabelsForField(platform, field);

  return (
    <Select
      selectedKey={value}
      className={cn('group flex w-full flex-col', className)}
      onSelectionChange={(selection) => onChange(selection as string)}
      aria-label="select operation"
    >
      <Button className="flex h-10 items-center justify-between overflow-hidden rounded-md border border-solid border-purple-300 bg-white text-primary shadow-sm placeholder:text-primary/50 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm">
        <SelectValue className="form-input text-left text-sm" />
        <span className="flex h-10 w-10 shrink-0 items-center justify-center bg-none">
          <Icon className="h-5 w-5 text-primary">
            <ChevronDown />
          </Icon>
        </span>
      </Button>
      <Popover className="max-h-60 overflow-auto">
        <ListBox
          items={options.map((x) => ({
            id: x,
            name: labels[x as keyof typeof labels],
          }))}
          className="overflow-y-auto"
        >
          {(item) => (
            <ListBoxItem
              id={item.id}
              key={item.id}
              className="relative cursor-default select-none gap-3 py-2 pl-9 pr-4 text-sm font-normal text-primary active:bg-purple-100 hover:bg-purple-100 focus-visible:bg-purple-100 selected:bg-purple-100 selected:font-semibold"
            >
              {item.name}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </Select>
  );
};

export default InsightsFormFilterMetricOperationSelect;
