import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import {
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS,
  InsightsFacebookWizardMetric,
  parseInsightsFacebookActionMetric,
} from '@magicbrief/common';
import { Icon } from 'src/components/Icon';
import { AriaButton } from 'src/components/Button/Button';
import Cube01 from 'src/assets/svgicons/duotone/cube-01.svg';
import Target04 from 'src/assets/svgicons/duotone/target-04.svg';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import { Tooltip } from 'src/components/Tooltip/Tooltip';
import { useParseMetric } from '../../util/useParseMetric';
import MetricDefinition from '../MetricDefinition';

type Props = {
  platform: 'tiktok' | 'facebook';
  metric: string;
  customEvents: string[] | null | undefined;
  customConversions:
    | Array<{ facebookId: string; name: string }>
    | null
    | undefined;
  customTrigger?: ({ children }: PropsWithChildren) => ReactNode;
} & React.ComponentProps<typeof AriaButton>;

export const InsightsMetricLabelTooltip: React.FC<Props> = ({
  metric,
  platform,
  customEvents,
  customConversions,
  customTrigger: CustomTrigger,
  ...buttonProps
}) => {
  const { getMetricLabelAsText, getMetricHeader } = useParseMetric();

  const label = getMetricLabelAsText(
    platform,
    metric,
    customEvents,
    customConversions
  );
  const header =
    getMetricHeader(platform, metric, customEvents, customConversions) ?? label;

  if (label == null) {
    return null;
  }

  let icon;
  const isCustomMetric =
    metric.startsWith('custom:') ||
    (metric.startsWith('action:') &&
      customEvents?.some((x) => {
        const action = parseInsightsFacebookActionMetric(metric);
        return action?.metric === x;
      }));
  if (isCustomMetric) {
    icon = (
      <span className="shrink-0" title="Custom Event">
        <Icon className="h-4 w-4 text-purple-600">
          <Cube01 />
        </Icon>
      </span>
    );
  }

  const isCustomConversion =
    metric.startsWith('action:') &&
    customConversions?.some((x) => {
      const action = parseInsightsFacebookActionMetric(metric);
      return `offsite_conversion.custom.${x.facebookId}` === action?.metric;
    });
  if (isCustomConversion) {
    icon = (
      <span className="shrink-0" title="Custom Conversion">
        <Icon className="h-4 w-4 text-purple-600">
          <Target04 />
        </Icon>
      </span>
    );
  }

  const isWizardMetric = INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS.includes(
    metric as InsightsFacebookWizardMetric
  );
  if (isWizardMetric) {
    icon = (
      <span className="shrink-0" title="Custom Metric">
        <Icon className="h-3.5 w-3.5 text-[#B4A2EF]">
          <MagicBriefBolt />
        </Icon>
      </span>
    );
  }

  const metricText = (
    <span className="flex flex-row items-center gap-1 overflow-hidden">
      {icon}
      <span className="overflow-hidden text-ellipsis text-nowrap">{label}</span>
    </span>
  );

  return (
    <Tooltip isDisabled={isCustomMetric} delay={1000} closeDelay={200}>
      {CustomTrigger ? (
        <CustomTrigger>{metricText}</CustomTrigger>
      ) : (
        <AriaButton
          {...buttonProps}
          style={{
            all: 'unset',
          }}
          className={clsx('!overflow-hidden', buttonProps.className)}
        >
          {metricText}
        </AriaButton>
      )}

      <div className="flex flex-col gap-1">
        <span className="truncate font-bold">{header}</span>
        <MetricDefinition metric={metric} platform={platform} />
      </div>
    </Tooltip>
  );
};
