import { captureException } from '@sentry/react';

// or-ing with (string & {}) allows us to specify literals, with autocomplete, while also allowing any string.
type SearchParam =
  | ('redirect' | 'referral' | 'view' | 'show-comments')
  // eslint-disable-next-line @typescript-eslint/ban-types
  | (string & {});

export const getQueryParam = (queryParam: SearchParam): string | null => {
  const url = new URLSearchParams(window.location.search);

  return url.get(queryParam);
};

export const addToSearchParams = (
  searchParam: SearchParam,
  value: string,
  updateHistory = false,
  shouldEncode = true
): URLSearchParams => {
  try {
    const url = new URL(window.location.toString());
    url.searchParams.set(
      searchParam,
      shouldEncode ? encodeURIComponent(value) : value
    );

    if (updateHistory) {
      history.replaceState(null, '', url.toString());
    }

    return url.searchParams;
  } catch (e) {
    console.error(e);
    captureException(e, {
      contexts: {
        addedSearchParam: {
          searchParam,
          value,
        },
      },
    });
    throw new Error('Could not add to search params');
  }
};

export const deleteFromSearchParams = (
  searchParam: SearchParam,
  updateHistory = false
): URLSearchParams => {
  try {
    const url = new URL(window.location.toString());
    if (!url.searchParams.getAll(searchParam).length) {
      return url.searchParams;
    }
    url.searchParams.delete(searchParam);

    if (updateHistory) {
      history.replaceState(null, '', url.toString());
    }

    return url.searchParams;
  } catch (e) {
    console.error(e);
    captureException(e, {
      contexts: {
        deletedSearchParam: {
          searchParam,
        },
      },
    });
    throw new Error('Could not delete from search params');
  }
};
