import {
  INSIGHTS_TIME_SERIES_TEXT_OPERATIONS_V2,
  INSIGHTS_TIME_SERIES_MULTI_OPTION_FIELD_OPERATIONS_V2,
  INSIGHTS_TIME_SERIES_NUMERIC_OPERATIONS_V2,
  INSIGHTS_TIME_SERIES_DATE_OPERATIONS_V2,
  InsightsNumericMetricOperationV2,
  InsightsDateFieldOperationV2,
  InsightsMultiOptionFieldOperationV2,
  InsightsTextFieldOperationV2,
  InsightsTagFieldOperationV2,
  INSIGHTS_TIME_SERIES_TAG_FIELD_OPERATIONS_V2,
  getIsMetricEnumerable,
  getMetricFormat,
} from '@magicbrief/common';

export const NUMERICAL_OPTION_LABELS: Record<
  InsightsNumericMetricOperationV2,
  string
> = {
  '=': 'Equals',
  '!=': 'Not equal to',
  '>': 'Greater than',
  '>=': 'Greater than or equal to',
  '<': 'Less than',
  '<=': 'Less than or equal to',
  between: 'Between',
  notBetween: 'Not between',
};

export const DATE_OPTION_LABELS: Record<InsightsDateFieldOperationV2, string> =
  {
    '=': 'On',
    '!=': 'Not on',
    '>': 'After',
    '<': 'Before',
    between: 'Between',
    notBetween: 'Not between',
    withinLast: 'Within last',
    withinDatePreset: 'Within selected time period',
  };

export const ENUMERABLE_OPTION_LABELS: Record<
  InsightsMultiOptionFieldOperationV2,
  string
> = {
  in: 'Is one of',
  notIn: 'Is not one of',
};

export const TEXT_OPTION_LABELS: Record<InsightsTextFieldOperationV2, string> =
  {
    '=': 'Is',
    '!=': 'Is not',
    contains: 'Contains',
    notContains: 'Does not contain',
    startsWith: 'Starts with',
    endsWith: 'Ends with',
    isSet: 'Is not empty',
    isNotSet: 'Is empty',
  };

export const TAG_OPTION_LABELS: Record<InsightsTagFieldOperationV2, string> = {
  in: 'As any of',
  notIn: 'As none of',
};

export function getOperationsAndLabelsForField(
  platform: 'facebook' | 'tiktok',
  field: string
) {
  if (field === 'tag') {
    return {
      options: INSIGHTS_TIME_SERIES_TAG_FIELD_OPERATIONS_V2,
      labels: TAG_OPTION_LABELS,
    };
  }

  const enumerable = getIsMetricEnumerable(platform, field);

  if (enumerable) {
    return {
      options: INSIGHTS_TIME_SERIES_MULTI_OPTION_FIELD_OPERATIONS_V2,
      labels: ENUMERABLE_OPTION_LABELS,
    };
  }

  const format = getMetricFormat(platform, field);

  switch (format) {
    case 'currency':
    case 'numeric':
    case 'percentage':
      return {
        options: INSIGHTS_TIME_SERIES_NUMERIC_OPERATIONS_V2,
        labels: NUMERICAL_OPTION_LABELS,
      };
    case 'text':
      return {
        options: INSIGHTS_TIME_SERIES_TEXT_OPERATIONS_V2,
        labels: TEXT_OPTION_LABELS,
      };
    case 'date':
      return {
        options: INSIGHTS_TIME_SERIES_DATE_OPERATIONS_V2,
        labels: DATE_OPTION_LABELS,
      };
    default: {
      format satisfies never;
      return {
        options: INSIGHTS_TIME_SERIES_NUMERIC_OPERATIONS_V2,
        labels: NUMERICAL_OPTION_LABELS,
      };
    }
  }
}
